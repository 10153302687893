import React, {useEffect, useState} from 'react'
import DashboardAPI from "../../../apis/dashboard"
import Typography from "@material-ui/core/Typography";
import useMountEffect from "../../../hooks/useMountEffect";
import {Grid} from "@material-ui/core";
import {numberWithCommas} from "../utils";


export default function StatsReport(
  {
    conditions,
    ...props
  }
) {

  const [stats, setStats] = useState({
    total_idea: 0,
    total_design: 0,
    total_product: 0,
    total_order: 0
  })

  useEffect(() =>  {
    if (conditions.fetch) {
      DashboardAPI.getStatsReport(conditions).then((response) => {
        setStats(response)
      })
    }
  }, [conditions])

  return (

    <Grid container spacing={4}>
      <Grid item xs={3}>
        <Typography size="xs" weight="medium" noWrap display="block" variant="subtitle1">
          Total idea
        </Typography>
        <Typography size="xxl" weight="medium" noWrap variant="h2">
          { numberWithCommas(stats.total_idea) }
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography size="xs" weight="medium" noWrap display="block" variant="subtitle1">
          Total design
        </Typography>
        <Typography size="xxl" weight="medium" noWrap variant="h2">
          { numberWithCommas(stats.total_design) }
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography size="xs" weight="medium" noWrap display="block" variant="subtitle1">
          Total product
        </Typography>
        <Typography size="xxl" weight="medium" noWrap variant="h2">
          { numberWithCommas(stats.total_product) }
        </Typography>
      </Grid>

      <Grid item xs={3}>
        <Typography size="xs" weight="medium" noWrap display="block" variant="subtitle1">
          Total order
        </Typography>
        <Typography size="xxl" weight="medium" noWrap variant="h2">
          { numberWithCommas(stats.total_order) }
        </Typography>
      </Grid>
    </Grid>


  )
}
