import React from 'react'
import {Grid} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import {DesignAPI} from "../../apis";
import AssignDesign from "./AssignDesign";

export default class IdealStepBase extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openDeleteConfirmBox: false
    }

    this.handleCloseConfirmBox = this.handleCloseConfirmBox.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
    this.allowAssign = true
    this.readOnly = this.props.readOnly === true
  }

  handleDelete() {
    this.setState({...this.state, openDeleteConfirmBox: true})
  }

  closeNotSave() {
    this.props.setSaveOnModalClose(false)
    this.props.setModalOpen(false)
  }

  handleCloseConfirmBox(selected) {
    this.setState({...this.state, openDeleteConfirmBox: false})
    if (!selected) {
      return
    }
    this.props.setNeedRefresh(true)
    DesignAPI.deleteIdea(this.props.idea.id).then(() => {

    }).finally(() => {
      this.closeNotSave()
    })
  }

  renderActions() {
    return (
      <>
      </>
    )
  }

  render() {
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={1} md={1} style={{'textAlign': 'left', 'paddingLeft': '30px'}}>
            <Button variant="contained" color="secondary" disabled={this.readOnly} onClick={this.handleDelete}>Delete</Button>
          </Grid>
          <Grid item xs={4} md={4}>
            <AssignDesign idea={this.props.idea} readOnly={this.readOnly} />
          </Grid>
          <Grid item xs={7} md={7}>
            {!this.allowAssign ? null : (
              <>{this.renderActions()}</>
            )}
          </Grid>
        </Grid>

        <Dialog
          open={this.state.openDeleteConfirmBox}
          onClose={this.handleCloseConfirmBox}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure want to delete this idea?</DialogTitle>
          <DialogActions>
            <Button onClick={() => {
              this.handleCloseConfirmBox(false)
            }} color="primary">
              Cancel
            </Button>
            <Button onClick={() => {
              this.handleCloseConfirmBox(true)
            }} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </>
    )
  }
}
