import React from 'react'
import {Button, Grid} from '@material-ui/core'
import IdeaModalEdit from './IdeaModalEdit'
import TaskPanel from './TaskPanel'
import TaskSearch from './TaskSearch'
import {useTaskState} from '../../context/TaskStateProvider'

export default function DesignList () {
  const { setNeedRefresh } = useTaskState()
  const [searchConditions, _setSearchConditions] = React.useState({})

  const setSearchConditions = (conditions) => {
    _setSearchConditions(conditions)
    setNeedRefresh()
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={2}>
        </Grid>
        <Grid item md={10}>
          <TaskSearch setSearchConditions={setSearchConditions} filterDate={false} filterDesigner={true}/>
        </Grid>
      </Grid>

      <br /><br />

      <Grid container spacing={3}>
        <Grid item md={3}>
          <TaskPanel title='Design' status='ASSIGN' searchConditions={searchConditions} />
        </Grid>
        <Grid item md={3}>
          <TaskPanel title='Done' status='DONE' searchConditions={searchConditions} />
        </Grid>
        <Grid item md={3}>
          <TaskPanel title='Fixing' status='FIX' searchConditions={searchConditions} />
        </Grid>
        <Grid item md={3}>
          <TaskPanel title='Review' status='RVW' searchConditions={searchConditions} />
        </Grid>
      </Grid>
      <IdeaModalEdit />
    </>
  )
}
