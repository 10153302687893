import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '../Wrappers'
import { useTaskState } from '../../context/TaskStateProvider'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import {Grid} from "@material-ui/core";


const formatDate = (date) => {
  return date.split('T')[0]
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(1.5),
    position: 'relative',
    cursor: 'pointer',
    overflow: 'visible'
  },
  reviewDone: {
    borderRight: '#04BF0D 3px solid',
  },
  details: {
    display: 'flex',
    flex: '1'
  },
  subTitle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical'
  },
  content: {
    flex: '1',
    padding: theme.spacing(1),
    paddingBottom: '2px !important',
  },
  cover: {
    width: 110,
    minWidth: 90,
    minHeight: 90,
    flex: '0 0 90'
  },
  actions: {
    position: 'absolute',
    right: '0px',
    top: '0px'
  },
  editStatus: {
    transition: 'transform .2s',
    margin: '0 auto',
    color: 'white',
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '6px 0px 3px 0px',
    transformOrigin: 'bottom left',
    '& span': {
      paddingTop: '0',
      marginRight: '2px',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'inline-block',
      borderWidth: '1px',
      borderStyle: 'solid',
      textAlign: 'center'
    },
  },
  editFinished: {
    background: '#04ad0d',
    borderColor: '#04AE0C',
  },
  editNotFinished: {
    background: '#c85d5e',
    borderColor: '#ae1919',
  },
  ideaId: {
    opacity: '0.6',
    display: 'inline-block',
    marginRight: '5px'
  },
  creatorName: {
    lineHeight: '16px'
  }
}))

export default function PanelItem (
  {
    design,
    ...props
  }
) {
  const classes = useStyles()
  const { setIdeaId, setModalOpen } = useTaskState()
  const idea = design

  const handleSetId = (id) => {
    setModalOpen(true)
    setIdeaId(id)
  }

  const renderThumbnail = () => {
    let url = 'https://placehold.jp/150x150.png'
    if (idea.cover_image) {
      url = idea.cover_image.thumbnail
    } else if (idea.images && idea.images.length) {
      url = idea.images[0].thumbnail
    }
    return (
      <CardMedia
        className={classes.cover}
        image={url}
      />
    )
  }

  return (
    <Card
      className={`${classes.root} ${design.is_review_done ? classes.reviewDone : ''}`}
      title={design.title}
      onClick={(e) => {handleSetId(design.id)}}>
      {renderThumbnail()}
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography variant="subtitle2" gutterBottom style={{fontWeight: '600', fontSize:'95%', paddingBottom: '5px'}}>
            <div className={classes.subTitle}>
            <b className={classes.ideaId}>#{design.id}</b> {design.title}
            </div>
          </Typography>

          <Grid container spacing={0}>
            <Grid item md={6} className={classes.creatorName}>
              <Typography size={'small'}  variant="caption">
              {!design.creator ? null : (
                <>{design.creator.name}</>
              )}
              </Typography>
            </Grid>
            <Grid item md={6} className={classes.creatorName} align={"right"}>
              <Typography size={'small'}  variant="caption">
                { formatDate(design.created_at)}
              </Typography>
            </Grid>

            <Grid item md={6} className={classes.creatorName} >
              <Typography size={'small'}  variant="caption">
              {!design.designer ? null : (
                <>{design.designer.name} &nbsp;</>
              )}
              </Typography>
            </Grid>
            <Grid item md={6} className={classes.creatorName}  align={"right"}>
              <Typography size={'small'}  variant="caption">
                { !design.assigned_at ? null : <>{ formatDate(design.assigned_at)}</>}
              </Typography>
            </Grid>
          </Grid>

          <div className={classes.editStatus}>
          {design.design_status.map((status) => {
            return (<span key={Math.random()} title={status.product_type} className={status.edit_finished ? classes.editFinished : classes.editNotFinished}>{status.product_type[0]}</span>)
          })}
          </div>
        </CardContent>
      </div>
    </Card>
  )
}
