import API from './base'

export default {
  login (email, password) {
    const params = new FormData()
    params.append('email', email)
    params.append('password', password)
    return API.post('auth', params)
  },
  getTeams() {
    return API.get('teams')
  },
  setTeam(teamId) {
    return API.post('teams/switch_team', {team_id: teamId})
  }
}
