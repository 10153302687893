import React from 'react'
import SoldList from "../../components/Product/SoldList"
import {TaskStateProvider} from "../../context/TaskStateProvider";

export default function SoldListPage() {
  return (
    <TaskStateProvider>
      <SoldList/>
    </TaskStateProvider>
  )
}
