import React from 'react';
import Grid from '@material-ui/core/Grid';
import DropZone from "../Upload/DropZone";
import {DesignAPI} from "../../apis";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from "@material-ui/core/Typography";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import TextField from "@material-ui/core/TextField";
import Cookies from "js-cookie";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { TagAutosuggest } from "./AutoSuggest";
import Radio from "@material-ui/core/Radio";
import VariantImage from "./VariantImage";
import {DEFAULT_OBJECT_PROP} from "../../config";
import PreviewImage from "./PreviewImage";
import InputCounter from "../InputCounter";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


function parseDesignColors(definedColors) {
  const colors = [];
  const schemas = [];
  for (let index in definedColors) {
    const [name, rest] = definedColors[index].split(':');
    const [schema, code] = rest.split('#');
    const invert = invertColor(code);
    colors.push({name, schema, invert, code: '#' + code});
    if (schema && !schemas.includes(schema)) {
      schemas.push(schema)
    }
  }
  return [colors, schemas]
}

function invertColor(hex) {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    let r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    return  '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    let zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

export default class DesignBase extends React.Component {
  constructor(props) {
    super(props)
    this.user = JSON.parse(Cookies.get('user'))
    this.design = props.design;
    this.platform = this.design.platform;
    this.isReadOnly = this.props.readOnly;
    this.supportTag = true;
    this.supportVariant = Object.keys(this.design.product_type.design_validations).length !== 0;
    this.supportMockup = Object.keys(this.design.product_type.mockup_validations).length !== 0;
    this.supportVariantChange = true;
    const [colors, schemas ] = parseDesignColors(this.design.product_type.colors.split(','));
    this.colors = colors;
    this.schemas = schemas;
    this.maxColors = 10;
    this.uploadMockupMessage = 'Click here to upload mockup';
    this.defaultImageClassName = props.classes.transparentBackground;
    /**
     * @type {number}
     * 0: max 13 tag
     * 1: max 250 characters, no limit tags
     */
    this.tagValidatorMode = 0

    this.state = {
      design: this.design,
      colors: this.design.colors,
      schema: null,
      expanded: false,
      imageClassName: props.classes.transparentBackground,
      imageStyle: {},
      confirmBoxOpen: false,
      confirmBoxAction: 'delete',
      confirmBoxContent: '',
      countTitle: this.design.title.length,
      countDescription: this.design.description.length,
      reasonRows: 4
    };

    this.handleChangeSchema = this.handleChangeSchema.bind(this);
    this.handleAddColor = this.handleAddColor.bind(this);
    this.handleOpenConfirmBox = this.handleOpenConfirmBox.bind(this);
    this.handleCloseConfirmBox = this.handleCloseConfirmBox.bind(this);
    this.handleChangeEditDone = this.handleChangeEditDone.bind(this);
    this.doValidate = this.doValidate.bind(this);
    this.basicValidate = this.basicValidate.bind(this);
    this.productTypeValidate = this.productTypeValidate.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  getMeta = (key, initial) => {
    initial = initial || '';
    const meta = this.design.meta;
    if (meta && meta.hasOwnProperty(key)) {
      if (meta[key].toLowerCase() === 'true') {
        return true
      }
      if (meta[key].toLowerCase() === 'false') {
        return false
      }
      return meta[key];
    }
    return initial
  }

  changeFormValue = (target) => {
    const targetName = target.name;
    if (targetName && this.design.hasOwnProperty(targetName)) {
      this.design[targetName] = target.value;
    }
    if (targetName.indexOf('meta.') === 0) {
      const metaKey = targetName.replace('meta.', '');
      this.design.meta[metaKey] = target.value;
    }
  }

  handleChangeAccordion = (panel) => (event, isExpanded) => {
    this.setState({...this.state, expanded: isExpanded ? panel : false});
  }

  updateElementHeight = (event) => {
    let length = event.target.value.split(/\r\n|\r|\n/).length + 1;
    if (length < 4) {
      length = 4;
    }
    this.setState({...this.state, reasonRows: length});
  }

  handleChangeFormElement = (event) => {
    this.changeFormValue(event.target);
    if (event.target.name=='reason') {
      this.updateElementHeight(event);
    }
  }

  handleChangeFormCheckBox = (event) => {
    event.target.value = event.target.checked ? true : false;
    this.changeFormValue(event.target);
    this.handleOnBlur()
  }

  handleUpdateTags = (tags) => {
    this.design.tags = tags;
    this.handleOnBlur();
  }

  onUploadDone = (data) => {
    if (data.object_prop === DEFAULT_OBJECT_PROP) {
      this.design.variants.push(data);
    } else {
      this.design.images.push(data);
    }
    this.props.refreshIdea();
  }

  handleChangeSchema = (event) => {
    const schema = event.target.value;
    const colors = [];
    for (let i in this.colors) {
      if (this.colors[i].schema && this.colors[i].schema === schema) {
        colors.push(this.colors[i].name);
      }
    }
    this.setState({...this.state, schema, colors});
    this.design.colors = colors;
    this.handleOnBlur();
  }

  handleAddColor(checked, color) {
    let _colors = this.state.colors;
    let imageStyle, imageClassName;
    if (checked) {
      if (_colors.length === this.maxColors) {
        alert(`Maximum ${this.maxColors} colors.`)
        return
      }
      _colors.push(color.name);
      imageStyle = {
        'backgroundColor': color.code,
        'backgroundImage': 'none'
      }
      imageClassName = '';
    } else {
      const index = _colors.indexOf(color.name);
      if (index >=0) {
        _colors.splice(index, 1);
      }
      imageStyle = {};
      imageClassName = this.defaultImageClassName;
    }
    this.setState({
      ...this.state,
      colors: _colors,
      imageStyle,
      imageClassName,
    });
    this.design.colors = _colors;
    this.props.saveIdea();
  }

  basicValidate () {
    const errors = []
    if (this.design.title.trim() === "") {
      errors.push("Title field could not be empty.")
    }
    if (this.supportTag && this.design.tags.length === 0) {
      errors.push("Tag field could not be empty.")
    }
    return errors
  }

  productTypeValidate () {
    return []
  }

  doValidate () {
    let errors = this.basicValidate().concat(this.productTypeValidate());
    if (errors.length !== 0) {
      alert(errors.join("\n"));
      return false;
    }
    return true;
  }

  handleChangeEditDone(event) {
    if (event.target.checked && !this.doValidate()) {
      return false;
    }
    this.design.edit_finished = event.target.checked;
    this.props.refreshIdea();
  }

  handleCloseConfirmBox(selected) {
    this.setState({...this.state, confirmBoxOpen: false});
    if(!selected) {
      return;
    }
    if (this.state.confirmBoxAction === 'delete') {
      this.props.deleteDesign(this.design.id);
    } else if (this.state.confirmBoxAction === 'clone') {
      DesignAPI.cloneDesign(this.design.id).then((response) => {
        this.props.onAddDesignDone(response, true);
      })
    }
  }

  handleOpenConfirmBox(action) {
    let confirmBoxContent = 'Are you sure want to delete?';
    if (action === 'clone' ) {
      confirmBoxContent = 'Are you sure want to clone?';
    }
    this.setState({...this.state, confirmBoxOpen: true, confirmBoxAction: action, confirmBoxContent: confirmBoxContent});
  }

  handleOnBlur() {
    this.props.saveIdea()
  }

  getValidationMessage(validations) {
    if (!validations) {
      return ''
    }
    let msg = ''
    for (let key in validations) {
      if (Array.isArray(validations[key])) {
        msg += `${key}: ` + validations[key].join(', ') + ' \n'
      } else {
        msg += `${key}: ${validations[key]} \n`
      }
    }
    return msg
  }

  allowUploadMore(section, validations) {
    if (!validations.hasOwnProperty('max_image')) {
      return true
    }
    const images = this.design[section].length
    return images < validations['max_image']
  }

  allowUploadMoreImage() {
    return this.allowUploadMore('images', this.design.product_type.mockup_validations)
  }
  allowUploadMoreVariant() {
    return this.allowUploadMore('variants', this.design.product_type.design_validations)
  }

  getAcceptedFileType(validations) {
    if (validations.hasOwnProperty('format')) {
      const format = []
      for (let index in validations.format) {
        format.push('image/' + validations.format[index])
      }
      return format.join(', ')
    }
    return null
  }

  renderForm = () => {
    return (
      <div>
        <TextField
          label="Title"
          name="title"
          fullWidth
          defaultValue={this.design.title}
          onChange={(event => {this.handleChangeFormElement(event); this.setState({...this.state, countTitle: event.target.value.length})})}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          inputProps={{
            maxLength: this.design.product_type.max_title
          }}
          onBlur={this.handleOnBlur}
        />
        <InputCounter currentValue={this.state.countTitle} maxLength={this.design.product_type.max_title}/>
      </div>
    )
  }

  renderDesignRequirementForm = () => {
    return (
      <>
        <TextField
          label="Requirement"
          name="requirement"
          fullWidth
          multiline
          rows={4}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          defaultValue={this.design.requirement}
          onChange={this.handleChangeFormElement}
          onBlur={this.handleOnBlur}
        />
        <TextField
          label="Fix reason"
          name="reason"
          fullWidth
          multiline
          rows={this.state.reasonRows}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          defaultValue={this.design.reason}
          onChange={this.handleChangeFormElement}
          onFocus={(event) => { this.updateElementHeight(event)}}
          onBlur={this.handleOnBlur}
        />
      </>
    )
  };

  renderConfirmBox = () => {
    return (
      <Dialog
        open={this.state.confirmBoxOpen}
        onClose={this.handleCloseConfirmBox}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.state.confirmBoxContent}</DialogTitle>
        <DialogActions>
          <Button onClick={()=>{this.handleCloseConfirmBox(false)}} color="primary">
            Cancel
          </Button>
          <Button onClick={()=>{this.handleCloseConfirmBox(true)}} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  renderFormAccordions = () => {
    const {classes} = this.props;
    return (
      <>
        <Accordion expanded={this.state.expanded === 'CREATOR'} onChange={this.handleChangeAccordion('CREATOR')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
          >
            <Typography className={classes.heading}><b>Design details</b></Typography>
          </AccordionSummary>
          <AccordionDetails style={{"display": "block"}}>
            <div>
              {this.renderForm()}
              {!this.supportTag ? null : (
                <TagAutosuggest tags={this.design.tags} updateTags={this.handleUpdateTags} validatorMode={this.tagValidatorMode} />
              )}
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={this.state.expanded === 'DESIGNER'} onChange={this.handleChangeAccordion('DESIGNER')}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon/>}
          >
            <Typography className={classes.heading}><b>Design requirements</b></Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div style={{"display": "block", "width": "100%"}}>
              {this.renderDesignRequirementForm()}
            </div>
          </AccordionDetails>
        </Accordion>
      </>
    )
  };

  renderColorField = () => {
    return (
      <Grid container spacing={2}>
        <Grid item md={9}>
          {this.schemas.map((item) => {
            return (
              <FormControlLabel
                key={item}
                control={<Radio
                  color="primary"
                  value={item}
                  checked={item==this.state.schema}
                  onChange={this.handleChangeSchema}
                />}
                label={`${item} schema`}
              />
            )
          })}
          <FormControlLabel
            control={<Radio
              color="primary"
              value=''
              checked={this.state.schema===''}
              onChange={this.handleChangeSchema}
            />}
            label={`Custom schema`}
          />
          <br />
          {this.colors.map((item) => {
            return (
              <FormControlLabel
                key={item.name}
                control={<Checkbox
                  checked={this.state.colors.includes(item.name)}
                  value={`${item.name}`}
                  onChange={(event) => {this.handleAddColor(event.target.checked, item)}}
                />}
                label={item.name}
              />
            )
          })}
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={2}>
          <TextField
            label="Price"
            name="price"
            fullWidth
            defaultValue={this.design.price}
            onChange={this.handleChangeFormElement}
            InputProps={{
              readOnly: this.isReadOnly,
            }}
            onBlur={this.handleOnBlur}
          />

          <FormControlLabel
            style={{'margin-left': '-22px', 'margin-top': '20px'}}
            control={
              <Checkbox
                name="meta.is_tm"
                color="primary"
                onChange={this.handleChangeFormCheckBox}
                defaultChecked={this.getMeta('is_tm')}
              />
            }
            title="Mark this design contains trademark"
            label="Is trademark"
          />
        </Grid>
      </Grid>
    )
  };

  render() {
    const {classes} = this.props;
    return (
      <Element name={`design-${this.design.id}`}>
        {this.renderConfirmBox()}

      <fieldset className={classes.fieldSet} id={`design-${this.design.id}`}>
        <legend><b>{this.design.product_type.full_name.toUpperCase()} #{this.design.id}  </b> {this.design.sku}</legend>
        <Grid container spacing={2} className={classes.panelItem}>
          <Grid item xs={2} md={2}>
            {this.design.images
              ? this.design.images.map((image, index) => {
                return <div key={image.id}>
                  <PreviewImage image={image}
                                index={index}
                                images={this.design.images}
                                refreshIdea={this.props.refreshIdea}
                                imageClass={this.state.imageClassName}
                                imageStyle={this.state.imageStyle}
                                setCoverFor={this.design.idea}
                                isReadOnly={this.isReadOnly} />
                </div>
              })
              : null}
            {!this.supportMockup || !this.allowUploadMoreImage() ? null : (
              <DropZone apiPath='images'
                        objectId={this.design.id}
                        objectType={"designs.design"}
                        onUploadDone={this.onUploadDone}
                        textContent={this.uploadMockupMessage}
                        accept={this.getAcceptedFileType(this.design.product_type.mockup_validations)}
                        extraTextContent={this.getValidationMessage(this.design.product_type.mockup_validations)}
              />
            )}

            {this.design.variants ? this.design.variants.map((image, index) => {
              return <VariantImage
                        key={image.id}
                        image={image}
                        index={index}
                        images={this.design.variants}
                        isReadOnly={this.isReadOnly}
                        refreshIdea={this.props.refreshIdea}
                        allowChange={this.supportVariantChange}
                        setCoverFor={this.design.idea}
                        variants={this.design.product_type.variants} />
            }) : null}

            {!this.supportVariant || !this.allowUploadMoreVariant() ? null : (
              <DropZone apiPath='images'
                        objectId={this.design.id}
                        objectType={"designs.design"}
                        objectProp={DEFAULT_OBJECT_PROP}
                        onUploadDone={this.onUploadDone}
                        textContent={`Click or drop to upload variant`}
                        accept={this.getAcceptedFileType(this.design.product_type.design_validations)}
                        extraTextContent={this.getValidationMessage(this.design.product_type.design_validations)}
              />
            )}
          </Grid>
          <Grid item xs={10} md={10}>
            {this.renderFormAccordions()}

            <div align={'right'}>

              {!!this.isReadOnly ? null : (
                <>
                <FormControlLabel
                  control={<Checkbox checked={this.design.edit_finished} onChange={this.handleChangeEditDone} name="checkedA" />}
                  label="Mark as done"
                />
                <Button onClick={()=>{this.handleOpenConfirmBox('delete')}}>Delete</Button>
                </>
                )}
                <Button onClick={()=>{this.handleOpenConfirmBox('clone')}}>Clone</Button>
            </div>
          </Grid>
        </Grid>
      </fieldset>
      </Element>
    )
  }
}

