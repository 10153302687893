import React from 'react'
import {Grid} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import TablePagination from "@material-ui/core/TablePagination"
import {ProductAPI} from "../../apis"
import SoldSearch from "./SoldSearch"
import SoldImport from "./SoldImport"
import {InsertLink, OpenInBrowser} from "@material-ui/icons"
import {useTaskState} from "../../context/TaskStateProvider"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import IdeaModalEdit from "../Designs/IdeaModalEdit"
import Hashids from 'hashids'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  action: {
    cursor: 'pointer',
    padding: '0 15px 0 0'
  },
  tableRow: {
    height: '30px'
  },
  cellThumbnail: {
    width: '100px',
    maxWidth: '100px',
    cursor: 'pointer',
  },
  cellThumbnailContainer: {
    width: '100px',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
  }
})

const formatDate = (date) => {
  return date.split('.')[0].split('T')[0]
}

const getHref = (platformId, orderId) => {
  return '/'
}

const hid = new Hashids('PMM', 3)

export default function SoldList() {
  const classes = useStyles()
  const {setModalOpen, setIdeaId, setDesignId} = useTaskState()
  const [orders, setOrders] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [total, setTotal] = React.useState(0)
  const [searchConditions, setSearchConditions] = React.useState({})

  React.useEffect(function () {
    loadData()
  }, [page, rowsPerPage, searchConditions])

  const loadData = () => {
    let conditions = Object.assign({}, searchConditions)
    conditions.context = 'list'
    conditions.limit = rowsPerPage
    conditions.page = page + 1

    ProductAPI.getOrders(conditions).then(({count, results, total}) => {
      setOrders(results)
      setCount(count)
      setTotal(total)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleSetId = (sku) => {
    const id = hid.decode(sku.split('-')[0].substring(1)).pop()
    const designId = hid.decode(sku.split('-')[1]).pop()
    setModalOpen(true)
    setIdeaId(id)
    setDesignId(designId)
  }

  const renderOrderInfo = (variations) => {
    if (typeof variations === 'object') {
      return (
        <TableContainer component={Paper}>
          <Table className={classes.tableVariant} size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>Sold</TableCell>
                <TableCell>Fit</TableCell>
                <TableCell>Color</TableCell>
                <TableCell>Size</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {variations.map((row) => (
                <TableRow key={row.name}  className={classes.tableRow}>
                  <TableCell>
                    {row.unitsSold}/{row.unitsReturned}/{row.unitsCancelled}
                  </TableCell>
                  <TableCell>{row.variationInfo.fit}</TableCell>
                  <TableCell>{row.variationInfo.color}</TableCell>
                  <TableCell>{row.variationInfo.size}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )
    }
    return (
      <>
        {variations}
      </>
    )
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={1}>
          <SoldImport/>
        </Grid>
        <Grid item md={4}></Grid>
        <Grid item md={7}>
          <SoldSearch setSearchConditions={setSearchConditions}/>
        </Grid>
      </Grid>

      <br/>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell><b>SKU</b></TableCell>
              <TableCell><b>Product type</b></TableCell>
              <TableCell><b>Title</b></TableCell>
              <TableCell><b>Order info</b></TableCell>
              <TableCell align='right'><b>Order at</b></TableCell>
              <TableCell align='right'><b>Qty</b></TableCell>
              <TableCell><b>Action</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders.map((order) => {
              return (
                <TableRow
                  key={order.id}
                  hover
                >
                  <TableCell component='th' scope='row' padding={'none'} className={classes.cellThumbnail}>
                    {!order.first_image ? null : (
                      <div className={classes.cellThumbnailContainer}>
                        <img src={order.first_image} className={classes.cellThumbnail} />
                      </div>
                    )}
                  </TableCell>
                  <TableCell component='th' scope='row'>
                    {order.product?.sku}
                  </TableCell>
                  <TableCell>
                    {order.product?.product_type}
                  </TableCell>
                  <TableCell>
                    {order.title}
                  </TableCell>
                  <TableCell>
                    {renderOrderInfo(order.variations)}
                  </TableCell>
                  <TableCell align='right'>{formatDate(order.order_at)}</TableCell>
                  <TableCell align='right'>{order.quantity}</TableCell>
                  <TableCell className={classes.action} align='right'>
                    {!order.product ? (
                      <Button
                        href={getHref(order.platform, order.order_id)}
                        target="_blank"
                        rel="noopener"
                      >
                        <InsertLink/>
                      </Button>
                    ) : (
                    <ButtonGroup variant="text" aria-label="text primary button group">
                      <Button onClick={(e) => {
                        e.preventDefault()
                        handleSetId(order.product.sku)
                      }}><OpenInBrowser/></Button>
                      <Button
                        href={getHref(order.platform, order.order_id)}
                        target="_blank"
                        rel="noopener"
                      >
                        <InsertLink/>
                      </Button>
                    </ButtonGroup>
                    )}
                  </TableCell>
                </TableRow>
              )
            })
            }
            <TableRow>
              <TableCell colSpan={4}></TableCell>
              <TableCell align='right'><b>Total:</b></TableCell>
              <TableCell align="right"><b>{total}</b></TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <IdeaModalEdit readOnly/>
    </>
  )
}
