import React from 'react'
import {Redirect, Route, Switch, withRouter} from 'react-router-dom'
import classnames from 'classnames'
import {Box} from '@material-ui/core'
// icons
// styles
import useStyles from './styles'
// components
import Header from '../Header/Header'
import Sidebar from '../Sidebar'
// pages
import Dashboard from '../../pages/dashboard'
import Icons from '../../pages/icons'
import Charts from '../../pages/charts'
import TaskDesignList from '../../pages/designs'
// context
import {useLayoutState} from '../../context/LayoutContext'
import ArchivedDesignList from '../../pages/designs/ArchivedDesignList'
import IdeaDesignList from '../../pages/designs/IdeaDesignList'
import ProductListPage from "../../pages/products";
import SoldListPage from "../../pages/sold";

function Layout (props) {
  const classes = useStyles()

  // global
  const layoutState = useLayoutState()

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            <Route path='/app/dashboard' component={Dashboard} />
            <Route path='/app/ideas-list' component={IdeaDesignList} />
            <Route path='/app/designs-list' component={TaskDesignList} />
            <Route path='/app/products' component={ProductListPage} />
            <Route path='/app/archived-list' component={ArchivedDesignList} />
            <Route path='/app/orders' component={SoldListPage} />
            <Route path='/app/ui' exact
              render={() => <Redirect to='/app/ui/icons' />}
            />
            <Route path='/app/ui/icons' component={Icons} />
            <Route path='/app/ui/charts' component={Charts} />
          </Switch>
          <Box
            mt={5}
            width='100%'
            display='flex'
            alignItems='center'
            justifyContent='space-between'
          />
        </div>
      </>
    </div>
  )
}

export default withRouter(Layout)
