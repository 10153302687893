import API from './base'

export default {
  getIdeal (id) {
    return API.get(`ideas/${id}`)
  },
  saveIdea (id, data) {
    return API.put(`ideas/${id}`, data)
  },
  deleteIdea (id) {
    return API.delete(`ideas/${id}`)
  },
  deleteIdeas (selected) {
    return API.delete(`ideas/bulk`, {data: {ids: selected}})
  },
  addDesign (ideaId, productType, tags) {
    const data = {
      idea: ideaId,
      product_type: productType,
      tags: tags
    }
    return API.post('designs', data)
  },
  cloneDesign (id) {
    return API.post(`designs/${id}/clone`)
  },
  saveDesign (designId, data) {
    return API.post('designs', data)
  },
  deleteDesign (id) {
    return API.delete(`designs/${id}`)
  },
  getDesigners () {
    return API.get('designers')
  },
  getCreators () {
    return API.get('creators')
  },
  getCreator (id) {
    return API.get('creators/' + id)
  },
  getIdeas (status, searchConditions) {
    searchConditions.status = status
    if (!searchConditions.hasOwnProperty('limit')) {
      searchConditions.limit = 300
    }
    return API.get('ideas', { params: searchConditions })
  },
  getMetadata (url) {
    return API.get('ideas/metadata?url=' + url)
  },
  addIdea (meta) {
    return API.post('ideas/add?get_creator=1&context=list', meta)
  },
  export (id) {
    return API.post('ideas/export', {'id': id})
  },
  moveToFix (id) {
    return API.post('ideas/fix', {'id': id})
  },
  cloneIdea(id, withAsset, initialStatus) {
    return API.post(`ideas/${id}/clone`, {'with_asset': withAsset, 'status': initialStatus})
  },
  setIdeaCover(id, assetId) {
    return API.patch(`ideas/${id}`, {'cover': assetId})
  }
}
