import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DropZone from "../Upload/DropZone"
import {ProductAPI} from "../../apis"
import {Grid} from "@material-ui/core"
import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import InputLabel from "@material-ui/core/InputLabel"
import FormHelperText from "@material-ui/core/FormHelperText"

export default function SoldImport() {
  const [open, setOpen] = React.useState(false)
  const [platforms, setPlatforms] = React.useState([])
  const [selectedPlatform, setSelectedPlatform] = React.useState(null)
  const [file, setFile] = React.useState(null)
  const [loading, setLoading] = React.useState()
  const [result, setResult] = React.useState({
    'imported': null,
    'skipped': null,
    'ignored': null
  })


  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setFile(null)
    setResult({
      'imported': null,
      'skipped': null,
      'ignored': null
    })
    setLoading(false)
  }

  const handleImport = () => {
    setLoading(true)
    ProductAPI.importOrders(selectedPlatform, file).then((result) => {
      setResult({...result})
      setLoading(false)
    }).catch((error) => {
      alert(error.detail)
      setLoading(false)
    })
  }

  const handleChangePlatform = (event) => {
    setSelectedPlatform(event.target.value)
  }

  const handleEnter = () => {
    ProductAPI.getPlatform().then(({results}) => {
      setPlatforms(results)
    })
  }

  const onUploadDone = (data) => {
    setFile(data)
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
      >
        Import
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        onEnter={handleEnter}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Import orders <b>{file ? (<>{file.file_name}</>) : null}</b>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {result.imported !== null ? (
              <div>
                Imported: <b>{result.imported}</b><br/>
                Skipped: <b>{result.skipped}</b><br/>
                Ignored: <b>{result.ignored}</b><br/>
              </div>
            ) : (
              <Grid container spacing={0}>
                <Grid item md={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-helper-label">Platform</InputLabel>
                    <Select
                      labelId="select-helper-label"
                      id="select-helper"
                      value={selectedPlatform}
                      onChange={handleChangePlatform}
                    >
                      {platforms.map((platform) => {
                        return (
                          <MenuItem value={platform.id}>{platform.name}</MenuItem>
                        )
                      })}
                    </Select>
                    <FormHelperText>Select platform to import orders</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={5}>
                  <DropZone
                    apiPath='orders/upload'
                    objectId={false}
                    objectType={"products.orders"}
                    onUploadDone={onUploadDone}
                    accept={"text/csv, application/csv, application/vnd.ms-excel"}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleImport}
            color="primary"
            variant="contained"
            autoFocus
            disabled={!file || !selectedPlatform || loading || result.imported !== null}
          >
            Start import
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
