import React from 'react'
import { TaskStateProvider } from '../../context/TaskStateProvider'
import ArchivedList from '../../components/Designs/ArchivedList'

export default function ArchivedDesignList () {
  return (
    <TaskStateProvider>
      <ArchivedList />
    </TaskStateProvider>
  )
}
