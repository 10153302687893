import API from './base'

export default {
  uploadFile(path, objectId, objectType, objectProp, file, fieldName) {
    fieldName = fieldName || 'original'
    path = path || 'images'
    const config = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
    const fd = new FormData()
    fd.append(fieldName, file)
    fd.append('object_type', objectType)
    fd.append('object_id', objectId)
    if (objectProp) {
      fd.append('object_prop', objectProp)
    }
    return API.post(path, fd, config)
  },
  deleteFile(id, path) {
    path = path || 'images'
    return API.delete(`${path}/${id}`)
  },
  updateImageFile(data) {
    const id = data.id
    const patch = {
      'object_prop': data.object_prop,
      'object_type': data.object_type,
      'object_id': data.object_id
    }
    return API.patch('images/' + id, patch)
  }
}
