import React, {useEffect, useState} from 'react'
import {Drawer, IconButton, List} from '@material-ui/core'
import {
  Archive as ArchiveIcon,
  ArrowBack as ArrowBackIcon,
  Home as HomeIcon,
  Image as ImageIcon,
  ShoppingCart as ShoppingCartIcon,
  AccountBalance as AccountBalanceIcon,
  WbIncandescent as IdeaIcon
} from '@material-ui/icons'
import {useTheme} from '@material-ui/styles'
import {withRouter} from 'react-router-dom'
import classNames from 'classnames'
// styles
import useStyles from './styles'
// components
import SidebarLink from './components/SidebarLink/SidebarLink'
// context
import {toggleSidebar, useLayoutDispatch, useLayoutState} from '../../context/LayoutContext'

const structure = [
  {
    id: 0,
    label: 'Dashboard',
    link: '/app/dashboard',
    icon: <HomeIcon/>
  },
  {
    id: -1,
    label: 'Ideas',
    link: '/app/ideas-list',
    icon: <IdeaIcon/>
  },
  {
    id: 1,
    label: 'Designs',
    link: '/app/designs-list',
    icon: <ImageIcon/>
  },
  {
    id: 2,
    label: 'Archived',
    link: '/app/archived-list',
    icon: <ArchiveIcon/>
  },
  {
    id: 6,
    label: 'Products',
    link: '/app/products',
    icon: <ShoppingCartIcon/>
  },
  {
    id: 7,
    label: 'Orders',
    link: '/app/orders',
    icon: <AccountBalanceIcon/>
  },
]

function Sidebar({location}) {
  const classes = useStyles()
  const theme = useTheme()

  // global
  const {isSidebarOpened} = useLayoutState()
  const layoutDispatch = useLayoutDispatch()

  // local
  const [isPermanent, setPermanent] = useState(true)

  useEffect(function () {
    window.addEventListener('resize', handleWindowWidthChange)
    handleWindowWidthChange()
    return function cleanup() {
      window.removeEventListener('resize', handleWindowWidthChange)
    }
  })

  return (
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened
        })
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar}/>
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse)
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            {...link}
          />
        ))}
      </List>
    </Drawer>
  )

  // ##################################################################
  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth
    const breakpointWidth = theme.breakpoints.values.md
    const isSmallScreen = windowWidth < breakpointWidth

    if (isSmallScreen && isPermanent) {
      setPermanent(false)
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true)
    }
  }
}

export default withRouter(Sidebar)
