import React from 'react'
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@material-ui/core'

const formatDate = (date) => {
  return date.split('.')[0].split('T')[0]
}

export default function TableComponent ({ data, classes }) {
  const keys = ['', 'SKU', 'IDEA', 'DESIGNER', 'TITLE', 'PLATFORM', 'ACCOUNT', 'QUANTITY', 'ORDER AT']

  return (
    <Table className='mb-0'>
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(({ id, image, creator_name, designer_name, product, title, platform, account, quantity, order_at }) => (
          <TableRow key={id}>
            <TableCell component='th' scope='row' padding={'none'} className={classes.cellThumbnail}>
              {!image ? null : (
                <div className={classes.cellThumbnailContainer}>
                  <img src={image} className={classes.cellThumbnail} />
                </div>
              )}
            </TableCell>
            <TableCell>{!product ? null : <>{product.sku}</>}</TableCell>
            <TableCell>{creator_name}</TableCell>
            <TableCell>{designer_name}</TableCell>
            <TableCell className='pl-3 fw-normal'>{title}</TableCell>
            <TableCell>{platform}</TableCell>
            <TableCell>{account}</TableCell>
            <TableCell>{quantity}</TableCell>
            <TableCell>{formatDate(order_at)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
