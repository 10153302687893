import API from './base'

export default {
  getPlatform () {
    return API.get('products/platforms')
  },
  getProductTypes(platform) {
    let params = {}
    if (platform) {
      params['platform'] = platform
    }
    return API.get('products/types', {params: params})
  },
  getTagSuggestion(term) {
    return API.get('ideas/tags', {params: {search: term}})
  },
  getProducts(conditions) {
    return API.get('products', {params: conditions})
  },
  export(productIds) {
    return API.post('products/export', {'products': productIds})
  },
  importOrders(platformId, file) {
    return API.post('orders/import', {'platform_id': platformId, 'file': file.full_name})
  },
  importProducts(file, sku, account, platformId) {
    const data = {
      file: file.full_name,
      sku: sku,
      account: account,
      platform_id: platformId
    }
    return API.post('products/import', data)
  },
  getOrders(conditions) {
    return API.get('orders', {params: conditions})
  }
}
