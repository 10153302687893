import React from 'react'
import {Grid} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import TablePagination from "@material-ui/core/TablePagination"
import {ProductAPI} from "../../apis"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import ProductSearch from "./ProductSearch"
import {OpenInBrowser as OpenInBrowserIcon} from "@material-ui/icons"
import {useTaskState} from "../../context/TaskStateProvider"
import IdeaModalEdit from "../Designs/IdeaModalEdit"
import ProductImport from "./ProductImport"
import Hashids from 'hashids'
import FormControlLabel from "@material-ui/core/FormControlLabel";

const hid = new Hashids('PMM', 3)

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  cellThumbnail: {
    width: '100px',
    maxWidth: '100px',
    cursor: 'pointer',
  },
  cellThumbnailContainer: {
    width: '100px',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
  },
  action: {
    cursor: 'pointer'
  }
})

const formatDate = (date) => {
  return date.split('.')[0].replace('T', ' ')
}

export default function ProductList() {
  const classes = useStyles()
  const {setModalOpen, setIdeaId, setDesignId} = useTaskState()
  const [showDesign, setShowDesign] = React.useState(true)
  const [products, setProducts] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [selected, setSelected] = React.useState([])
  const [productTypes, setProductTypes] = React.useState([])
  const [searchConditions, setSearchConditions] = React.useState({})

  React.useEffect(function () {
    loadData()
  }, [page, rowsPerPage, searchConditions])

  const loadData = () => {
    let conditions = Object.assign({}, searchConditions)
    conditions.context = 'list'
    conditions.limit = rowsPerPage
    conditions.offset = page * rowsPerPage

    ProductAPI.getProducts(conditions).then(({count, results}) => {
      setProducts(results)
      setCount(count)
      setSelected([])
      setProductTypes([])
    })
  }

  const handleExport = () => {
    ProductAPI.export(selected).then((result) => {
      window.location = result.file_url
    }).catch((error) => {
      alert(error.detail)
    })
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      // const newSelecteds = products.map((n) => n.design)
      const newSelecteds = []
      const newProductTypes = []
      for(let item in products) {
        newSelecteds.push(products[item].design)
        if(newProductTypes.indexOf(products[item].product_type) === -1) {
          newProductTypes.push(products[item].product_type)
        }
      }
      setSelected(newSelecteds)
      setProductTypes(newProductTypes)
    } else {
      setSelected([])
      setProductTypes([])
    }
  }

  const handleChangeThumbnailOption = (event) => {
    setShowDesign(event.target.checked)
  }

  const handleSetId = (sku) => {
    const id = hid.decode(sku.split('-')[0].substring(1)).pop()
    const designId = hid.decode(sku.split('-')[1]).pop()
    setModalOpen(true)
    setIdeaId(id)
    setDesignId(designId)
  }

  const handleClick = (event, name, type) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    let newType = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected)

    for(let p in products) {
      if (newSelected.includes(products[p].design)) {
        if(newType.indexOf(products[p].product_type) === -1) {
          newType.push(products[p].product_type)
        }
      }
    }
    setProductTypes(newType)
  }

  const renderLink = (product) => {
    if(!product.platform_url) {
      return null
    }

    return (
      <a href={product.platform_url} target="_blank" rel="noreferrer noopener">{product.platform_id}</a>
    )
  }

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Button
            variant="contained"
            color="primary"
            title="Export selected design"
            onClick={handleExport}
            disabled={selected.length === 0 || productTypes.length > 1}
          >
            Export
          </Button>
          &nbsp; &nbsp;
          <ProductImport loadData={loadData} />
          &nbsp; &nbsp;
          <FormControlLabel
            control={<Checkbox checked={showDesign} onChange={handleChangeThumbnailOption} />}
            label="Show design"
          />

        </Grid>
        <Grid item md={9}>
          <ProductSearch setSearchConditions={setSearchConditions}/>
        </Grid>
      </Grid>

      <br />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < products.length}
                  checked={products.length > 0 && selected.length === products.length}
                  onChange={handleSelectAllClick}
                  inputProps={{'aria-label': 'select all products'}}
                />
              </TableCell>
              {!showDesign ? null : (
              <TableCell>
                <b>Design</b>
              </TableCell>
              )}
              <TableCell><b>SKU</b></TableCell>
              <TableCell><b>Product type</b></TableCell>
              <TableCell><b>Title</b></TableCell>
              <TableCell><b>Account</b></TableCell>
              <TableCell><b>Platform id</b></TableCell>
              <TableCell align='right'><b>Date created</b></TableCell>
              <TableCell align='right'><b>Date changed</b></TableCell>
              <TableCell align='right'><b>Status</b></TableCell>
              <TableCell align='right'></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => {
              const isItemSelected = isSelected(product.design);
              const labelId = `enhanced-table-checkbox-${product.design}`;
              return (
                <TableRow
                  key={product.design}
                  hover
                  onClick={(event) => handleClick(event, product.design, product.product_type)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                >
                  <TableCell component='th' padding="checkbox">
                    <Checkbox
                      checked={isItemSelected}
                      inputProps={{'aria-labelledby': labelId}}
                    />
                  </TableCell>
                  {!showDesign ? null : (
                  <TableCell padding={'none'}>
                    {!product.first_image ? null : (
                      <div className={classes.cellThumbnailContainer}>
                        <img src={product.first_image.thumbnail} className={classes.cellThumbnail}/>
                      </div>
                    )}
                  </TableCell>
                  )}
                  <TableCell scope='row'>
                    {product.sku}
                  </TableCell>
                  <TableCell>
                    {product.product_type}
                  </TableCell>
                  <TableCell>
                    {product.title}
                  </TableCell>
                  <TableCell>{product.account}</TableCell>
                  <TableCell>
                    {renderLink(product)}
                  </TableCell>
                  <TableCell align='right'>{formatDate(product.created_at)}</TableCell>
                  <TableCell align='right'>{formatDate(product.updated_at)}</TableCell>
                  <TableCell className={classes.action} align='right'>{product.status}</TableCell>
                  <TableCell className={classes.action} align='right' onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    handleSetId(product.sku)
                  }}><OpenInBrowserIcon/></TableCell>
                </TableRow>
              )
            })
            }
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <IdeaModalEdit readOnly/>
    </>
  )
}
