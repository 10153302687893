import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import IdeaDetail from './IdeaDetail'
import useDetailStyles from '../../components/Designs/styles'
import {useTaskState} from '../../context/TaskStateProvider'
import LinearProgress from '@material-ui/core/LinearProgress'
import {DesignAPI} from '../../apis'
import DesignMerch from './DesignMerch'
import DesignSeller from './DesignSeller'
import DesignEtsy from "./DesignEtsy"
import IdeaStepAssign from './IdeaStepAssign'
import IdeaStepDesign from './IdeaStepDesign'
import IdeaStepDone from "./IdeaStepDone"
import IdeaStepReview from "./IdeaStepReview"
import IdeaStepArchive from "./IdeaStepArchive";
import { animateScroll as scroll, scroller } from 'react-scroll'

function getModalStyle() {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: '20px',
    transform: `translate(-${top}%, -${left}%)`
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    height: 'calc(100vh - 20px)',
    padding: theme.spacing(0),
    outline: 'none',
    display: 'block',
    overflow: 'hidden',
    borderRadius: '6px'
  },
  paperContent: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 120px)',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    marginTop: '50px',
    display: 'block',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  paperHeader: {
    position: 'fixed',
    top: '1px',
    left: '-1px',
    right: '0px',
    height: '50px',
    backgroundColor: '#EEE',
    paddingLeft: theme.spacing(4)
  },
  paperFooter: {
    position: 'fixed',
    bottom: '1px',
    left: '0px',
    height: '70px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(6),
    textAlign: 'right'
  }
}))

let idea = null

console.warn = console.error = () => {};

const recusiveAssign = (key, desc, value) => {
  if (desc.hasOwnProperty(key)) {
    if (typeof value === 'object') {
      for (let _key in value) {
        recusiveAssign(_key, desc[key], value[_key])
      }
    } else {
      desc[key] = value
    }
  } else {
    desc[key] = value
  }
}

const recusiveUpdate = (desc, source) => {
  for (let key in source) {
    recusiveAssign(key, desc, source[key])
  }
}

const scrollToDesign = (id) => {
    setTimeout(() => {
      scroller.scrollTo(`design-${id}`, {
        containerId: 'modalBody',
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      })
    }, 500)
  }

export default function IdeaModalEdit(
  {
    readOnly,
    ...props
  }
) {
  const detailClasses = useDetailStyles()
  const contentElement = React.useRef(null)
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [_idea, _setIdea] = React.useState(null)
  const {ideaId, setIdeaId, designId, modalOpen, setModalOpen, setNeedRefresh, saveOnModalClose, setSaveOnModalClose} = useTaskState()
  const [disabledExport, setDisabledExport] = React.useState(true)

  React.useEffect(function () {
    if (ideaId) {
      DesignAPI.getIdeal(ideaId).then((data) => {
        setIdea(data)
        if (designId) {
          scrollToDesign(designId)
        }
      })
    }
  }, [ideaId])

  React.useEffect(() => {
    if(modalOpen === false) {
      setIdeaId(null)
      setIdea(null)
    }
  }, [modalOpen])

  const handleClose = () => {
    setModalOpen(false)
    if (saveOnModalClose) {
      saveIdea(true, () => {})
    } else {
      setSaveOnModalClose(true)
    }
  }

  const saveIdea = (refreshList, callback, errorCallback) => {
    refreshList = refreshList == undefined ? true : refreshList
    callback = callback || null
    errorCallback = errorCallback || null

    DesignAPI.saveIdea(idea.id, idea).then((data) => {
      if (callback) {
        callback(data)
      } else {
        setIdea(data)
      }
      if (refreshList) {
        setNeedRefresh(false)
      }
    }).catch(({data, status}) => {
      if (status > 499) {
        alert("Server error, please try again later.")
      }

      if (errorCallback) {
        errorCallback(data)
      }
      let errorMsg = ''
      for (let i in data) {
        if (typeof data[i] == "string") {
          errorMsg += `${data[i]}\n`
        } else {
          for (let j in data[i]) {
            errorMsg += `${data[i][j]}\n`
          }
        }
      }
      alert(errorMsg)
      console.log(errorMsg)
    })
  }

  const saveIdeaNoRefresh = () => {
    saveIdea(false, (data) => {
      setIdea(data)
    })
  }

  const setIdea = (ideaData) => {
    if (idea === null || ideaData === null) {
      if(ideaData===null) {
        idea = null
      } else {
        idea = ideaData
      }
    } else {
      recusiveUpdate(idea, ideaData)
    }
    _setIdea(ideaData)

    if (!ideaData) {
      return
    }

    let _disabledExport = false
    for (let key in ideaData.designs) {
      if(ideaData.designs[key].edit_finished === false) {
        _disabledExport = true
      }
    }
    setDisabledExport(_disabledExport)
  }

  const refreshIdea = () => {
    const _idea = Object.assign({}, idea)
    setIdea(_idea)
  }

  const deleteDesign = (id) => {
    DesignAPI.deleteDesign(id).finally(() => {
      let index = 0;
      for (index in idea.designs) {
        if (idea.designs[index].id === id) {
          break
        }
      }
      idea.designs.splice(index, 1)
      refreshIdea()
    })
  }

  const onAddDesignDone = (design, push) => {
    if (push) {
      idea.designs.push(design)
      refreshIdea()
    }
    contentElement && contentElement.current.scrollIntoView({behavior: 'smooth'})
  }

  const renderDesigns = (design) => {
    if (design.product_type.full_name.toLowerCase().indexOf('merch') >= 0) {
      return <DesignMerch design={design} readOnly={!!readOnly}
                          refreshIdea={refreshIdea}
                          deleteDesign={deleteDesign}
                          onAddDesignDone={onAddDesignDone}
                          saveIdea={saveIdeaNoRefresh}
                          classes={detailClasses}/>
    } else if (design.product_type.full_name.toLowerCase().indexOf('etsy') >= 0) {
      return <DesignEtsy design={design} readOnly={!!readOnly}
                         refreshIdea={refreshIdea}
                         deleteDesign={deleteDesign}
                         onAddDesignDone={onAddDesignDone}
                         saveIdea={saveIdeaNoRefresh}
                         classes={detailClasses}/>
    }
    return <DesignSeller design={design} readOnly={!!readOnly}
                         refreshIdea={refreshIdea}
                         deleteDesign={deleteDesign}
                         onAddDesignDone={onAddDesignDone}
                         saveIdea={saveIdeaNoRefresh}
                         classes={detailClasses}/>
  }

  const getBody = () => {
    if (idea === null) {
      return (<div><LinearProgress color='secondary'/></div>)
    }
    return (
      <>
        <IdeaDetail idea={idea}
                    setIdea={setIdea}
                    saveIdea={saveIdeaNoRefresh}
                    onAddDesignDone={onAddDesignDone}
                    readOnly={readOnly}/>
        {idea.designs
          ? idea.designs.map(design => {
            return <div key={design.id}>{renderDesigns(design)}</div>
          })
          : null}
      </>
    )
  }

  const getFooter = () => {
    if (idea === null) {
      return null
    }

    if (readOnly) {
      return <IdeaStepArchive key='design'
                               idea={idea}
                               readOnly={readOnly}
                               saveIdea={saveIdea}
                               setNeedRefresh={setNeedRefresh}
                               setSaveOnModalClose={setSaveOnModalClose}
                               setModalOpen={setModalOpen}/>
    }

    switch (idea.status) {
      case 'ASSIGN':
      case 'FIX':
        return <IdeaStepDesign key='design'
                               idea={idea}
                               saveIdea={saveIdea}
                               setNeedRefresh={setNeedRefresh}
                               setSaveOnModalClose={setSaveOnModalClose}
                               setModalOpen={setModalOpen}/>
      case 'DONE':
        return <IdeaStepDone key='done' idea={idea} saveIdea={saveIdea}
                               setNeedRefresh={setNeedRefresh}
                               setSaveOnModalClose={setSaveOnModalClose}
                               setModalOpen={setModalOpen}
                               disabledExport={disabledExport}
                               refreshIdea={refreshIdea}/>
      case 'RVW':
        return <IdeaStepReview key='review'
                               idea={idea}
                               saveIdea={saveIdea}
                               setNeedRefresh={setNeedRefresh}
                               setSaveOnModalClose={setSaveOnModalClose}
                               setModalOpen={setModalOpen}/>

      default:
        return <IdeaStepAssign key='assign' idea={idea}
                               saveIdea={saveIdea}
                               setNeedRefresh={setNeedRefresh}
                               setSaveOnModalClose={setSaveOnModalClose}
                               setModalOpen={setModalOpen}/>
    }
  }

  return (
    <div>
      <Modal
        disableEnforceFocus
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby='edit-modal-title'
        aria-describedby='edit-modal-description'
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.paperHeader}>
            <h3>{_idea !== null ? (<>[#{_idea.id}] {_idea.url ?
              <a href={_idea.url} target='_blank'>{_idea.title}</a> : <>{_idea.title}</>}</>) : null}</h3>
          </div>
          <div className={classes.paperContent} id={"modalBody"}>
            {getBody()}
            <div ref={contentElement}/>
          </div>
          <div className={classes.paperFooter}>
            {getFooter()}
          </div>
        </div>
      </Modal>
    </div>
  )
}
