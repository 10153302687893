import React, {useEffect, useState} from 'react'
import DashboardAPI from "../../../apis/dashboard"
import Typography from "@material-ui/core/Typography";
import Widget from "../../../components/Widget";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {useTheme} from '@material-ui/styles'
import useStyles from "../styles"

export default function OrderReport(
  {
    conditions,
    ...props
  }
) {

  const classes = useStyles()
  const theme = useTheme()

  const [data, setData] = useState([])

  useEffect(() => {
    if (conditions.fetch) {
      DashboardAPI.getOrderReport(conditions).then((response) => {
        setData(response)
      })
    }
  }, [conditions])

  return (

    <Widget
      bodyClass={classes.mainChartBody}
      header={
        <div className={classes.mainChartHeader}>
          <Typography
            variant='h5'
            color='text'
            colorBrightness='secondary'
          >
            Order by days
          </Typography>
        </div>
      }
    >
      <ResponsiveContainer width='100%' minWidth={500} height={350}>
         <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 0,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar dataKey="physical" stackId="a" fill="#8884d8" />
          <Bar dataKey="digital" stackId="a" fill="#82ca9d" />
          <Bar dataKey="merch" stackId="a" fill="#f89821" />
        </BarChart>
      </ResponsiveContainer>
    </Widget>


  )
}
