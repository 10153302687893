import React, { useContext, useState } from 'react'

const TaskStateContext = React.createContext(null)

export function useTaskState () {
  const state = useContext(TaskStateContext)
  if (!state) {
    throw new Error('useTaskState must be used within TaskStateContext')
  }
  return state
}

export function TaskStateProvider ({ children }) {
  const [ideaId, setIdeaId] = useState(null)
  const [designId, setDesignId] = useState(null)
  const [modalOpen, _setModalOpen] = useState(false)
  const [needRefresh, _setNeedRefresh] = useState(null)
  const [saveOnModalClose, setSaveOnModalClose ] = useState(true)

  function setNeedRefresh () {
    _setNeedRefresh(new Date())
  }

  function setModalOpen(open) {
    _setModalOpen(open)
    if (!open) {
      setDesignId(null)
    }
  }

  const providerValues = {
    ideaId,
    setIdeaId,
    designId,
    setDesignId,
    modalOpen,
    setModalOpen,
    needRefresh,
    setNeedRefresh,
    saveOnModalClose,
    setSaveOnModalClose
  }

  return <TaskStateContext.Provider value={providerValues}>{children}</TaskStateContext.Provider>
}
