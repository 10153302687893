import React from 'react'
import ProductList from '../../components/Product/ProductList'
import {TaskStateProvider} from "../../context/TaskStateProvider";

export default function ProductListPage () {
  return (
    <TaskStateProvider>
      <ProductList />
    </TaskStateProvider>
  )
}
