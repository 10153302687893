import React, {useEffect, useState} from 'react'
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@material-ui/core'
import DashboardAPI from "../../../apis/dashboard"
import {numberWithCommas} from "../utils";


export default function CreatorReport(
  {
    conditions,
    ...props
  }
) {

  const keys = ['NAME', 'IDEA', 'DESIGN', 'ORDER', 'DIGITAL', 'MERCH', 'QUANTITY']
  const [records, setRecords] = useState([])

  useEffect(() => {
    if (conditions.fetch) {
      DashboardAPI.getCreatorReport(conditions).then((response) => {
        setRecords(response)
      })
    }
  }, [conditions])

  return (
    <Table className='mb-0'>
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <>
            { key === "NAME" ? <TableCell key={key}>{key}</TableCell> :  <TableCell key={key} align={"right"}>{key}</TableCell> }
            </>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {records.map(({ name, total_idea, total_design, total_order, total_digital, total_merch, total_quantity }) => (
          <TableRow key={name}>
            <TableCell>{name}</TableCell>
            <TableCell className='pl-3 fw-normal' align={"right"}>{ numberWithCommas(total_idea) }</TableCell>
            <TableCell align={"right"}>{ numberWithCommas(total_design) }</TableCell>
            <TableCell align={"right"}>{ numberWithCommas(total_order) }</TableCell>
            <TableCell align={"right"}>{ numberWithCommas(total_digital) }</TableCell>
            <TableCell align={"right"}>{ numberWithCommas(total_merch) }</TableCell>
            <TableCell align={"right"}>{ numberWithCommas(total_quantity) }</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
