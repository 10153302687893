import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import {DEFAULT_OBJECT_PROP} from "../../config";
import {AssetAPI} from "../../apis";
import {makeStyles} from "@material-ui/core/styles";
import PreviewImage from "./PreviewImage";

const useStyles = makeStyles({
  formControl: {
    marginBottom: '15px'
  }
})

export default function VariantImage(
  {
    image,
    index,
    images,
    variants,
    isReadOnly,
    refreshIdea,
    allowChange,
    setCoverFor,
    ...props
  }
) {
  const classes = useStyles()
  const [variant, setVariant] = React.useState(image.object_prop)

  const handleChange = (event) => {
    setVariant(event.target.value)
    image.object_prop = event.target.value
    AssetAPI.updateImageFile(image)
  }

  return (
    <div>
      <PreviewImage
        image={image}
        index={index}
        images={images}
        refreshIdea={refreshIdea}
        setCoverFor={setCoverFor}
        isReadOnly={isReadOnly} />
      {!allowChange ? null : (
        <div>
          <FormControl className={classes.formControl} fullWidth>
            <Select
              value={variant}
              onChange={handleChange}
              displayEmpty
              className={classes.selectEmpty}
              inputProps={{'aria-label': 'Select variant'}}
            >
              <MenuItem value={DEFAULT_OBJECT_PROP} disabled>
                Select variant
              </MenuItem>
              {variants.map(variant => {
                return <MenuItem key={variant} value={variant}>{variant}</MenuItem>
              })}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  )
}
