import React from 'react'
import useStyles from './styles'
import PanelItem from './PanelItem'
import { DesignAPI } from '../../apis'
import { useTaskState } from '../../context/TaskStateProvider'
import Typography from '@material-ui/core/Typography'

export default function TaskPanel (
  {
    title,
    status,
    searchConditions,
    ...props
  }
) {
  const classes = useStyles()
  const [designs, setDesigns] = React.useState([])
  const [total, setTotal] = React.useState('...')
  const { needRefresh, setNeedRefresh } = useTaskState()

  React.useEffect(function () {
    let conditions = {}
    if (searchConditions) {
      conditions = searchConditions
    }
    conditions.context = 'list'
    conditions.get_design_status = '1'
    conditions.get_designer = '1'
    conditions.get_creator = '1'
    conditions.ignore_image = true
    if (!conditions.hasOwnProperty('designer') || !conditions.hasOwnProperty('creator') || !conditions.hasOwnProperty('search')) {
      return
    }

    DesignAPI.getIdeas(status, conditions).then(({ results, count }) => {
      setDesigns(results)
      setTotal(`(${count})`)
    })
  }, [needRefresh, searchConditions])

  return (
    <div>
      <Typography component='h5' variant='h5' style={{'margin-bottom': '15px'}}>
        {title} <span style={{'font-size': '80%', 'opacity': '0.7'}}>{total}</span>
      </Typography>
      <div>
        {designs.map((design) => {
          return <PanelItem key={design.id} design={design} />
        })}
      </div>
    </div>
  )
}
