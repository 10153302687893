import React from "react";
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import {DesignAPI} from "../../apis";
import useMountEffect from "../../hooks/useMountEffect";
import Grid from "@material-ui/core/Grid";
import { Typography } from '../Wrappers'

export default function AssignDesign(
  {
    idea,
    readOnly,
    ...props
  }
) {
  const [designers, setDesigners] = React.useState([])
  const [creator, setCreator] = React.useState([])
  const [selectedDesigner, setSelectedDesigner] = React.useState(idea.designer)
  const [highPriority, setHighPriority] = React.useState(idea.priority === 0 ? true : false)

  useMountEffect(() => {
    DesignAPI.getDesigners().then(({results}) => {
      setDesigners(results)
    })
    if (idea.creator) {
      DesignAPI.getCreator(idea.creator).then((result) => {
        console.log(result)
        setCreator(result)
      })
    }
  })

  const handleCheckBox = (event) => {
    setHighPriority(event.target.checked);
    idea.priority = event.target.checked ? 0 : 3;
  }

  const handleChangeDesigner = (event) => {
    setSelectedDesigner(event.target.value);
    idea.designer = event.target.value;
  }

  return (
    <Grid container>
      <Grid item md={4}>
      <FormControlLabel
        control={
          <Checkbox
            color="primary"
            checked={highPriority}
            onChange={handleCheckBox}
            disabled={readOnly}
          />
        }
        label="High priority"
      />
      </Grid>
      <Grid item md={3}>
        {!creator ? null : (
          <Typography variant={'body1'} display={'block'} style={{marginTop: 7, verticalAlign: 'bottom'}}>{creator.name}</Typography>
        )}
      </Grid>
      <Grid item md={5}>
      <FormControl fullWidth>
        <Select
          value={selectedDesigner}
          onChange={handleChangeDesigner}
          inputProps={{'aria-label': 'Select designer'}}
          label={'Select designer'}
          disabled={readOnly}
        >
          <MenuItem value={null}>
            Select designer
          </MenuItem>
          {designers.map((designer) => {
            return <MenuItem value={designer.id}>{designer.name}</MenuItem>
          })}
        </Select>
      </FormControl>
      </Grid>
    </Grid>
  )
}
