import React, {useEffect} from 'react'
import {DesignAPI, ProductAPI} from '../../apis'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useMountEffect from '../../hooks/useMountEffect'
import TextField from '@material-ui/core/TextField'
import {Grid} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function TaskSearch(
  {
    setSearchConditions,
    filterDesigner,
    filterDate,
    ...props
  }
) {

  const [selectedDateFrom, setSelectedDateFrom] = React.useState(null);
  const [selectedDateTo, setSelectedDateTo] = React.useState(null);

  const [designers, setDesigners] = React.useState([])
  const [creators, setCreators] = React.useState([])
  const [platforms, setPlatforms] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState('')
  const [conditions, setConditions] = React.useState({
    designer: '',
    creator: '',
    search: '',
    platform: '',
    created_at_from: '',
    created_at_to: '',
  })

  useMountEffect(() => {
    if (filterDesigner) {
      DesignAPI.getDesigners().then(({results}) => {
        setDesigners(results)
      })
      ProductAPI.getPlatform().then(({results}) => {
        setPlatforms(results)
      })
    }
    DesignAPI.getCreators().then(({results}) => {
      setCreators(results)
    })
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setConditions({...conditions, search: searchTerm})
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    const _conditions = Object.assign({}, conditions)
    setSearchConditions(_conditions)
  }, [conditions])

  const handleChangeDesigner = (event) => {
    setConditions({...conditions, designer: event.target.value})
  }

  const handleChangeCreator = (event) => {
    setConditions({...conditions, creator: event.target.value})
  }

  const handleChangePlatform = (event) => {
    setConditions({...conditions, platform: event.target.value})
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date)
    setConditions({...conditions, created_at_from: date})
  }

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date)
    setConditions({...conditions, created_at_to: date})
  }

  return (
    <div>
      <Grid container spacing={1} justify="flex-end">
        {!filterDesigner ? null : (
          <>
            <Grid item md={2}>
              <FormControl fullWidth>
                <Select
                  value={conditions.platform}
                  onChange={handleChangePlatform}
                  displayEmpty
                  inputProps={{'aria-label': 'All platform'}}
                >
                  <MenuItem key={'_'} value=''>All platform</MenuItem>
                  {platforms.map((ps) => {
                    return <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item md={2}>
              <FormControl fullWidth>
                <Select
                  value={conditions.designer}
                  onChange={handleChangeDesigner}
                  displayEmpty
                  inputProps={{'aria-label': 'All designers'}}
                >
                  <MenuItem value=''>
                    All designers
                  </MenuItem>
                  {designers.map((designer) => {
                    return <MenuItem key={designer.id} value={designer.id}>{designer.name}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.creator}
              onChange={handleChangeCreator}
              displayEmpty
              inputProps={{'aria-label': 'All creators'}}
            >
              <MenuItem value=''>
                All creators
              </MenuItem>
              {creators.map((creator) => {
                return <MenuItem key={creator.id} value={creator.id}>{creator.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <TextField
            fullWidth
            placeholder='Search by ID, tags, title...'
            onChange={handleChangeSearchTerm}
          />
        </Grid>
        {!filterDate ? null : (
          <>
            <Grid item md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="date-picker-from"
                  format="MM/dd/yyyy"
                  autoOk={true}
                  value={selectedDateFrom}
                  onChange={handleDateChangeFrom}
                  KeyboardButtonProps={{
                    'aria-label': 'Select sale date from',
                  }}
                  style={{"marginTop": '0'}}
                  title="Select created date from"
                  placeholder={"Select created date from"}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item md={2}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  margin="normal"
                  id="date-picker-to"
                  format="MM/dd/yyyy"
                  autoOk={true}
                  value={selectedDateTo}
                  onChange={handleDateChangeTo}
                  KeyboardButtonProps={{
                    'aria-label': 'Select created date to',
                  }}
                  style={{"marginTop": '0'}}
                  title="Select created date to"
                  placeholder={"Select created date to"}
                />
              </MuiPickersUtilsProvider>
            </Grid>
          </>
        )}
      </Grid>
    </div>
  )
}
