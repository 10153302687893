import React from 'react'
import {Grid} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IdeaModalEdit from './IdeaModalEdit'
import TaskSearch from './TaskSearch'
import {useTaskState} from '../../context/TaskStateProvider'
import {makeStyles} from '@material-ui/core/styles'
import {DesignAPI} from '../../apis'
import TablePagination from "@material-ui/core/TablePagination"
import {OpenInBrowser as OpenInBrowserIcon, ControlPointDuplicate as ControlPointDuplicateIcon} from '@material-ui/icons'
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from '@material-ui/lab/Alert'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  cellThumbnail: {
    width: '100px',
    maxWidth: '100px',
    cursor: 'pointer',
  },
  cellThumbnailContainer: {
    width: '100px',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
  },
  action: {
    cursor: 'pointer'
  }
})

const status = 'ARCH'
let selectedId = 0


function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const formatDate = (date) => {
  return date.split('.')[0].split('T')[0]
}

export default function ArchivedList() {
  const classes = useStyles()
  const {setModalOpen, setIdeaId} = useTaskState()
  const [searchConditions, _setSearchConditions] = React.useState({})
  const [designs, setDesigns] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [showDupDialog, setShowDupDialog] = React.useState(false)
  const [showAlert, setShowAlert] = React.useState(false)
  const [clonedIdeaId, setClonedIdeaId] = React.useState(0)
  const [fetching, setFetching] = React.useState(false)
  const [initialStatus, setInitialStatus ] = React.useState('SPY')

  const setSearchConditions = (conditions) => {
    setPage(0);
    _setSearchConditions(conditions)
  }

  React.useEffect(function () {
    let conditions = {}
    if (searchConditions) {
      conditions = searchConditions
    }
    conditions.context = 'list'
    conditions.limit = rowsPerPage
    conditions.offset = page * rowsPerPage
    conditions.ignore_images = true
    conditions.get_designer = 1
    conditions.get_creator = 1
    conditions.ordering = "-id"
    if (!conditions.hasOwnProperty('designer') || !conditions.hasOwnProperty('creator') || !conditions.hasOwnProperty('search')) {
      return
    }
    if (fetching) {
      return
    }
    setFetching(true)
    DesignAPI.getIdeas(status, conditions).then(({count, results}) => {
      setDesigns(results)
      setCount(count)
    }).finally(() => {
      setFetching(false)
    })
  }, [searchConditions, page, rowsPerPage])

  const handleSetId = (id) => {
    setModalOpen(true)
    setIdeaId(id)
  }

  const handleSetDupId = (id) => {
    selectedId = id
    setShowDupDialog(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleCloseConfirmBox = (withAsset) => {
    setShowDupDialog(false)
    DesignAPI.cloneIdea(selectedId, withAsset, initialStatus).then(({id}) => {
      setClonedIdeaId(id)
      setShowAlert(true)
    }).catch((error) => {
      alert(error)
    })
  }

  const handleAlertClose = () => {
    setShowAlert(false)
  }

  const handleSelectInitialStatus = (event) => {
    setInitialStatus(event.target.value)
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={2}>
        </Grid>
        <Grid item md={10}>
          <TaskSearch setSearchConditions={setSearchConditions} filterDesigner={true} filterDate={true}/>
          <br/>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellThumbnail}></TableCell>
              <TableCell><b>Design title</b></TableCell>
              <TableCell><b>Creator / Designer</b></TableCell>
              <TableCell align='right'><b>Date created</b></TableCell>
              <TableCell align='right'><b>Action</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {designs.map((design) => (
              <TableRow key={design.id}>
                <TableCell component='th' scope='row' padding={'none'} className={classes.cellThumbnail}>
                  {!design.cover_image ? null : (
                    <div className={classes.cellThumbnailContainer}>
                      <img src={design.cover_image.thumbnail} className={classes.cellThumbnail} onClick={() => {
                        handleSetId(design.id)
                      }}/>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <b style={{'display': 'inline-block', 'margin-right': '5px', 'opacity': '0.6'}}>#{design.id}</b> {design.title}
                </TableCell>
                <TableCell>
                  {design.creator?.name} /&nbsp;
                  {design.designer?.name}
                </TableCell>
                <TableCell align='right' title={design.created_at}>{formatDate(design.created_at)}</TableCell>
                <TableCell className={classes.action} align='right'>
                  <ButtonGroup variant="text" aria-label="text primary button group">
                    <Button onClick={() => {handleSetId(design.id)}} title={'View this idea'}><OpenInBrowserIcon /></Button>
                    <Button onClick={() => {handleSetDupId(design.id)}} title={'Clone this idea'}  >
                      <ControlPointDuplicateIcon />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <IdeaModalEdit readOnly/>

    <Dialog
      open={showDupDialog}
      onClose={() => {setShowDupDialog(false)}}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">What's the clone option do you prefer?</DialogTitle>
      <DialogContent>
        <div>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              value={initialStatus}
              onChange={handleSelectInitialStatus}
            >
              <FormControlLabel value="SPY" control={<Radio />} label="Move to idea page" />
              <FormControlLabel value="FIX" control={<Radio />} label="Move to Fix column" />
              <FormControlLabel value="ASSIGN" control={<Radio />} label="Move to Design column" />
            </RadioGroup>
          </FormControl>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {setShowDupDialog(false)}} color="primary">
          Cancel
        </Button>
        <Button onClick={() => {handleCloseConfirmBox(true)}} color="secondary">
          With assets
        </Button>
        <Button onClick={() => {handleCloseConfirmBox(false)}} color="secondary">
          Without assets
        </Button>
      </DialogActions>
    </Dialog>

    <Snackbar open={showAlert} autoHideDuration={6000} onClose={handleAlertClose}>
      <Alert onClose={handleAlertClose} severity='success'>
        New idea <b>#[{clonedIdeaId}]</b> was clone from <b>#[{selectedId}]</b>.
      </Alert>
    </Snackbar>
    </>
  )
}
