import React from 'react'
import PropTypes from 'prop-types'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import {withStyles} from '@material-ui/core/styles'
import ChipInput from 'material-ui-chip-input'
import {ProductAPI} from "../../apis";


function renderInput(inputProps) {
  const {value, onChange, chips, ref, ...other} = inputProps

  return (
    <ChipInput
      clearInputValueOnChange
      onUpdateInput={onChange}
      fullWidth
      fullWidthInput
      value={chips}
      inputRef={ref}
      InputProps={{inputProps: {maxLength: 550}}}
      {...other}
    />
  )
}

function renderSuggestion(suggestion, {query, isHighlighted}) {
  const name = typeof suggestion == "object" ? suggestion.name : suggestion
  if (undefined === name) {
    return null
  }

  const matches = match(name, query)
  const parts = parse(name, matches)

  return (
    <MenuItem
      selected={isHighlighted}
      component='div'
      onMouseDown={(e) => e.preventDefault()} // prevent the click causing the input to be blurred
    >
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span key={String(index)} style={{fontWeight: 500}}>
              {part.text}
            </span>
          ) : (
            <span key={String(index)}>
              {part.text}
            </span>
          )
        })}
      </div>
    </MenuItem>
  )
}

function renderSuggestionsContainer(options) {
  const {containerProps, children} = options

  return (
    <Paper {...containerProps} square>
      {children}
    </Paper>
  )
}

function getSuggestionValue(suggestion) {
  return suggestion.name
}

const styles = theme => ({
  container: {
    flexGrow: 1,
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit * 3,
    left: 0,
    right: 0,
    zIndex: 1
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  textField: {
    width: '100%'
  },
  textHelper: {
    fontSize: '80%',
    textAlign: 'right',
  },
  copyTag: {
    display: 'inline-block',
    cursor: 'pointer',
    padding: '5px',
    fontWeight: 'bold',
    paddingRight: '15px'
  },
  counter: {
    display: 'inline-block',
    padding: '5px'
  }
})

function copyToClipboard(str) {
  const el = document.createElement('textarea')
  el.setAttribute('readonly', '')
  el.value = str
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

function getTagCharacterLength (tags) {
  return tags.join().length
}


class ReactAutosuggestRemote extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      suggestions: props.tags,
      value: props.tags,
      totalCharacters: getTagCharacterLength(props.tags),
      textFieldInput: '',
      textCopy: 'Copy tags'
    }
    this.handleCopyTag = this.handleCopyTag.bind(this)
    this.validatorMode = this.props.validatorMode
  }


  handleCopyTag = () => {
    copyToClipboard(this.state.value.join(', '))
    this.setState({...this.state, textCopy: 'Copied ✓'})
    setTimeout(() => {
      this.setState({...this.state, textCopy: 'Copy tags'})
    }, 2000)
  }

  handleSuggestionsFetchRequested = ({value}) => {
    const self = this
    ProductAPI.getTagSuggestion(value).then((response) => {
      self.setState({
        suggestions: response
      })
    }).catch((e) => {
      console.log(e)
    })
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  handletextFieldInputChange = (event, {newValue}) => {
    this.setState({
      textFieldInput: newValue
    })
  }

  handleAddChip(chip) {
    const _chips = chip.split(/[\;,\,]+/)
    const chips = []
    for (let index in _chips) {
      let _chip = _chips[index].trim()
      if (this.validatorMode == 0 && (!_chip || _chip.length > 20)) {
        continue
      }
      chips.push(_chip)
    }
    let temp = this.state.value.concat(chips)
    let _totalCount = 0
    if (this.validatorMode == 0) {
      // Max 13 tags
      temp = temp.splice(0, 13)
      _totalCount = getTagCharacterLength(temp)
    } else {
      // Max 250 on total tags
      const _allowed = []
      for(let i in temp) {
        if (!temp[i]) {
          continue
        }
        _totalCount += temp[i].length
        if (_totalCount <= 250) {
          _allowed.push(temp[i])
        }
      }
      temp = _allowed
    }
    this.setState({value: temp,  totalCharacters: _totalCount})
    this.props.updateTags && this.props.updateTags(temp)
  }

  handleDeleteChip(chip, index) {
    const temp = this.state.value
    temp.splice(index, 1)
    this.setState({value: temp, totalCharacters: getTagCharacterLength(temp)})
    this.props.updateTags && this.props.updateTags(temp)
  }

  render() {
    const {classes, tags, ...rest} = this.props
    return (
      <>
      <Autosuggest
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
          suggestion: classes.suggestion
        }}
        renderInputComponent={renderInput}
        suggestions={this.state.suggestions}
        onSuggestionsFetchRequested={this.handleSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
        renderSuggestionsContainer={renderSuggestionsContainer}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionSelected={(e, { suggestionValue }) => { this.handleAddChip(suggestionValue); e.preventDefault() }}
        focusInputOnSuggestionClick
        inputProps={{
          classes,
          placeholder: 'Enter tags',
          chips: this.state.value,
          onChange: this.handletextFieldInputChange,
          value: this.state.textFieldInput,
          onAdd: (chip) => this.handleAddChip(chip),
          onDelete: (chip, index) => this.handleDeleteChip(chip, index),
          ...rest
        }}
      />
      <div className={classes.textHelper}>
        <span className={classes.copyTag} onClick={this.handleCopyTag}>{this.state.textCopy}</span>
        {this.validatorMode == 0 ? <span className={classes.counter}>{this.state.value.length} / 13</span> : <span className={classes.counter}>{this.state.totalCharacters}</span> }
      </div>
      </>
    )
  }
}

ReactAutosuggestRemote.propTypes = {
  classes: PropTypes.object.isRequired
}
export const TagAutosuggest = withStyles(styles)(ReactAutosuggestRemote)
