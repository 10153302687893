import React from "react";
import IdealStepBase from "./IdeaStepBase";
import Button from "@material-ui/core/Button";
import {DesignAPI} from "../../apis";
import MuiAlert from '@material-ui/lab/Alert';
import {useTaskState} from "../../context/TaskStateProvider";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export const withHooksHOC = (Component) => {
  return (props) => {
    const {setSaveOnModalClose, setNeedRefresh} = useTaskState();
    return <Component setNeedRefresh={setNeedRefresh} setSaveOnModalClose={setSaveOnModalClose} {...props} />
  }
}

class IdeaStepDone extends IdealStepBase {
  constructor(props) {
    super(props)
    this.state = {
      alertOpen: false,
      total: 0
    }
    this.handleExport = this.handleExport.bind(this)
    this.handleAlertClose = this.handleAlertClose.bind(this)
  }

  handleAlertClose = (event, reason) => {
    this.setState({...this.state, alertOpen: false})
  }

  handleExport = () => {
    const self = this
    DesignAPI.export(this.props.idea.id).then((response) => {
      self.setState({...this.state, alertOpen: true, total: response.total})
      self.props.setNeedRefresh()
      self.closeNotSave()
    }).catch((error) => {
      console.log(error)
    })
  }

  handleNeedToFix = () => {
    const self = this
    DesignAPI.moveToFix(this.props.idea.id).then((response) => {
      self.props.setNeedRefresh()
      self.closeNotSave()
    }).catch((error) => {
      console.log(error)
    })
  }

  handleMoveReview = () => {
    const oldStatus = this.props.idea.status
    this.props.idea.status = 'RVW'
    this.props.saveIdea(true, () => {
      this.closeNotSave()
    }, () => {
      this.props.idea.status = oldStatus
    })
  }

  renderActions() {
    return (
      <>
        <Button variant="contained" color="secondary" onClick={this.handleNeedToFix}>
          Move to fix
        </Button>
        &nbsp;
        <Button variant="contained" color="primary" onClick={this.handleMoveReview}>
          Move to review
        </Button>
      </>
    )
  }
}

export default withHooksHOC(IdeaStepDone)
