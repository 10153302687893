import React from 'react'
import { TaskStateProvider } from '../../context/TaskStateProvider'
import IdeaList from '../../components/Designs/IdeaList'

export default function IdeaDesignList () {
  return (
    <TaskStateProvider>
      <IdeaList />
    </TaskStateProvider>
  )
}
