import React, {useEffect} from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import {Grid} from '@material-ui/core'
import ProductPlatformFilter from "./ProductPlatformFilter";
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import useMountEffect from "../../hooks/useMountEffect";
import {DesignAPI, ProductAPI} from "../../apis";

const status = [
  {'key': 0, 'name': 'Created'},
  {'key': 3, 'name': 'Exported'},
  {'key': 5, 'name': 'Uploaded'},
  {'key': 7, 'name': 'Sold'},
]

export default function ProductSearch(
  {
    setSearchConditions,
    ...props
  }
) {
  const toDate =  new Date();
  const fromDate = new Date();
  fromDate.setDate(toDate.getDate() - 7);

  const [selectedDateFrom, setSelectedDateFrom] = React.useState(fromDate)
  const [selectedDateTo, setSelectedDateTo] = React.useState(toDate)
  const [designers, setDesigners] = React.useState([])
  const [creators, setCreators] = React.useState([])
  const [searchTerm, setSearchTerm] = React.useState('');
  const [conditions, setConditions] = React.useState({
    status: '',
    search: '',
    designer: '',
    creator: '',
    platform: '',
    product_type: '',
    created_at_from: '',
    created_at_to: '',
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setConditions({...conditions, search: searchTerm})
    }, 500)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    const _conditions = Object.assign({}, conditions)
    setSearchConditions(_conditions)
  }, [conditions])


  useMountEffect(() => {
    DesignAPI.getDesigners().then(({results}) => {
      setDesigners(results)
    })
    DesignAPI.getCreators().then(({results}) => {
      setCreators(results)
    })
  })

  const handleChangeStatus = (event) => {
    setConditions({...conditions, status: event.target.value})
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  const handleChangePlatform = (value) => {
    setConditions({...conditions, platform: value})
  }

  const handleChangeProductType = (value) => {
    setConditions({...conditions, product_type: value})
  }

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date)
    setConditions({...conditions, created_at_from: date})
  }

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date)
    setConditions({...conditions, created_at_to: date})
  }

  const handleChangeDesigner = (event) => {
    setConditions({...conditions, designer: event.target.value})
  }

  const handleChangeCreator = (event) => {
    setConditions({...conditions, creator: event.target.value})
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={3}>
          <TextField
            fullWidth
            placeholder='Search by SKU, tags, title, idea ID...'
            onChange={handleChangeSearchTerm}
          />
        </Grid>
        <Grid item md={3}>
          <ProductPlatformFilter
            showProductType
            onChangePlatform={handleChangePlatform}
            onChangeProductType={handleChangeProductType}
          />
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.status}
              onChange={handleChangeStatus}
              displayEmpty
              inputProps={{'aria-label': 'All status'}}
            >
              <MenuItem value=''>
                All status
              </MenuItem>
              {status.map((s) => {
                return <MenuItem key={s.key} value={s.key}>{s.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.creator}
              onChange={handleChangeCreator}
              displayEmpty
              inputProps={{'aria-label': 'All creators'}}
            >
              <MenuItem value=''>
                All creators
              </MenuItem>
              {creators.map((creator) => {
                return <MenuItem key={creator.id} value={creator.id}>{creator.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.designer}
              onChange={handleChangeDesigner}
              displayEmpty
              inputProps={{'aria-label': 'All designers'}}
            >
              <MenuItem value=''>
                All designers
              </MenuItem>
              {designers.map((designer) => {
                return <MenuItem key={designer.id} value={designer.id}>{designer.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={8}></Grid>
        <Grid item md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-from"
              format="MM/dd/yyyy"
              autoOk={true}
              value={selectedDateFrom}
              onChange={handleDateChangeFrom}
              KeyboardButtonProps={{
                'aria-label': 'Select sale date from',
              }}
              style={{"marginTop": '0'}}
              title="Select created date from"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-to"
              format="MM/dd/yyyy"
              autoOk={true}
              value={selectedDateTo}
              onChange={handleDateChangeTo}
              KeyboardButtonProps={{
                'aria-label': 'Select created date to',
              }}
              style={{"marginTop": '0'}}
              title="Select created date to"
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  )
}
