import React from 'react'
import { TaskStateProvider } from '../../context/TaskStateProvider'
import DesignList from '../../components/Designs/DesignList'

export default function TaskDesignList () {
  return (
    <TaskStateProvider>
      <DesignList />
    </TaskStateProvider>
  )
}
