import axios from 'axios'
import Cookies from 'js-cookie'
import { CORE_API_BASE_URL, CORE_API_VERSION } from '../config.js'

axios.defaults.baseURL = `${CORE_API_BASE_URL}/${CORE_API_VERSION}/`
axios.defaults.withCredentials = false

axios.interceptors.request.use(
  function (config) {
    const authToken = Cookies.get('token')
    if (authToken) {
      config.headers.common.Authorization = `Token ${authToken}`
    }
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error.response)
  }
)
export default axios
