import React from "react";
import TextField from "@material-ui/core/TextField";
import {default as DesignBase} from "./DesignBase";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import InputCounter from "../InputCounter";

function get(meta, key) {
  if (!meta.hasOwnProperty(key)) {
    return null
  }
  if (meta[key].toLowerCase() === 'true') {
    return true
  }
  if (meta[key].toLowerCase() === 'false') {
    return false
  }
  return meta[key]
}

export default class DesignMerch extends DesignBase {
  constructor(props) {
    super(props)
    this.supportTag = false
    this.state['countBranch'] = this.design.branch.length
    this.state['countKey1'] = this.getMeta('key1').length
    this.state['countKey2'] = this.getMeta('key2').length
  }

  productTypeValidate () {
    const errors = []
    const meta = this.design.meta || {};
    if (this.design.branch === "") {
      errors.push("Branch field could not be empty.")
    }
    if (this.design.colors.length === 0) {
      errors.push("Color field could not be empty.")
    }
    if (!get(meta, 'gender.male') && !get(meta, 'gender.female') && !get(meta, 'gender.youth')) {
      errors.push("Please select at least one gender.")
    }
    return errors
  }

  renderForm = () => {
    return (
      <>
        <TextField
          label="Title"
          name="title"
          fullWidth
          defaultValue={this.design.title}
          onChange={(event => {this.handleChangeFormElement(event); this.setState({...this.state, countTitle: event.target.value.length})})}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          inputProps={{
            maxLength: this.design.product_type.max_title
          }}
          onBlur={this.handleOnBlur}
        />
        <InputCounter currentValue={this.state.countTitle} maxLength={this.design.product_type.max_title}/>
        <TextField
          label="Branch"
          name="branch"
          fullWidth
          defaultValue={this.design.branch}
          onChange={(event => {this.handleChangeFormElement(event); this.setState({...this.state, countBranch: event.target.value.length})})}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          inputProps={{
            maxLength: this.design.product_type.max_branch
          }}
          onBlur={this.handleOnBlur}
        />
        <InputCounter currentValue={this.state.countBranch} maxLength={this.design.product_type.max_branch}/>
        <TextField
          label="Key 1"
          name="meta.key1"
          fullWidth
          defaultValue={this.getMeta('key1')}
          onChange={(event => {this.handleChangeFormElement(event); this.setState({...this.state, countKey1: event.target.value.length})})}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          inputProps={{
            maxLength: 255
          }}
          onBlur={this.handleOnBlur}
        />
        <InputCounter currentValue={this.state.countKey1} maxLength={255}/>
        <TextField
          label="Key 2"
          name="meta.key2"
          fullWidth
          defaultValue={this.getMeta('key2')}
          onChange={(event => {this.handleChangeFormElement(event); this.setState({...this.state, countKey2: event.target.value.length})})}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          inputProps={{
            maxLength: 255
          }}
          onBlur={this.handleOnBlur}
        />
        <InputCounter currentValue={this.state.countKey2} maxLength={255}/>
        <TextField
          label="Description"
          name="description"
          multiline
          rows={4}
          fullWidth
          defaultValue={this.design.description}
          onChange={(event => {this.handleChangeFormElement(event); this.setState({...this.state, countDescription: event.target.value.length})})}
          InputProps={{
            readOnly: this.isReadOnly,
          }}
          inputProps={{
            maxLength: this.design.product_type.max_description
          }}
          onBlur={this.handleOnBlur}
        />
        <InputCounter currentValue={this.state.countDescription} maxLength={this.design.product_type.max_description}/>
        <FormControlLabel
          control={
            <Checkbox
              name="meta.gender.male"
              color="primary"
              onChange={this.handleChangeFormCheckBox}
              defaultChecked={this.getMeta('gender.male')}
            />
          }
          label="Male"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="meta.gender.female"
              color="primary"
              onChange={this.handleChangeFormCheckBox}
              defaultChecked={this.getMeta('gender.female')}
            />
          }
          label="Female"
        />
        <FormControlLabel
          control={
            <Checkbox
              name="meta.gender.youth"
              color="primary"
              onChange={this.handleChangeFormCheckBox}
              defaultChecked={this.getMeta('gender.youth')}
            />
          }
          label="Youth"
        />
        <div>
        {this.renderColorField()}
        </div>
      </>
    )
  }
}
