import React, {useEffect} from 'react'
import {ProductAPI} from '../../apis'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useMountEffect from '../../hooks/useMountEffect'
import {Grid} from '@material-ui/core'

export default function ProductPlatformFilter(
  {
    showProductType,
    onChangePlatform,
    onChangeProductType,
    ...props
  }
) {
  const column = showProductType ? 6 : 12
  const [platform, setPlatform] = React.useState('')
  const [platforms, setPlatforms] = React.useState([])
  const [productType, setProductType] = React.useState('')
  const [productTypes, setProductTypes] = React.useState([])


  useMountEffect(() => {
    ProductAPI.getPlatform().then(({results}) => {
      setPlatforms(results)
    })
  })

  useEffect(() => {
    if (!platform) {
      setProductTypes([])
      setProductType('')
      return
    }
    ProductAPI.getProductTypes(platform).then(({results}) => {
      setProductTypes(results)
    })
  }, [platform])

  const handleChangePlatform = (event) => {
    setPlatform(event.target.value)
    onChangePlatform && onChangePlatform(event.target.value)
  }

  const handleChangeProductType = (event) => {
    setProductType(event.target.value)
    onChangeProductType && onChangeProductType(event.target.value)
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={column}>
          <FormControl fullWidth>
            <Select
              value={platform}
              onChange={handleChangePlatform}
              displayEmpty
              inputProps={{'aria-label': 'All platform'}}
            >
              <MenuItem key={'_'} value=''>All platform</MenuItem>
              {platforms.map((ps) => {
                return <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        {!showProductType ? null : (
          <Grid item md={6}>
            <FormControl fullWidth>
              <Select
                value={productType}
                onChange={handleChangeProductType}
                displayEmpty
                inputProps={{'aria-label': 'All product type'}}
              >
                <MenuItem key={'_'} value=''>All product type</MenuItem>
                {productTypes.map((ps) => {
                  return <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  )
}
