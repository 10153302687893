import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DropZone from "../Upload/DropZone"
import {ProductAPI} from "../../apis"
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";

export default function ProductImport(
{
    loadData,
    ...props
}) {
  const [open, setOpen] = React.useState(false)
  const [file, setFile] = React.useState(null)
  const [columnSKU, setColumnSKU] = React.useState('SKU')
  const [columnAccount, setColumnAccount] = React.useState('Account')
  const [columnPlatformId, setColumnPlatformId] = React.useState('Platform id')
  const [loading, setLoading] = React.useState()
  const [result, setResult] = React.useState({
    'imported': null,
    'skipped': null,
    'ignored': null
  })

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setFile(null)
    setResult({
      'imported': null,
      'skipped': null,
      'ignored': null
    })
    setLoading(false)
  }

  const handleImport = () => {
    setLoading(true)
    ProductAPI.importProducts(file, columnSKU, columnAccount, columnPlatformId).then((result) => {
      setResult({...result})
      setLoading(false)
      loadData && loadData()
    }).catch((error) => {
      alert(error.detail)
      setLoading(false)
    })
  }


  const onUploadDone = (data) => {
    setFile(data)
  }

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClickOpen}
      >
        Import
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Import products {file ? (<>from file <b>{file.file_name}</b></>) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {result.imported !== null ? (
              <div>
                Imported: <b>{result.imported}</b><br/>
                Skipped: <b>{result.skipped}</b><br/>
                Ignored: <b>{result.ignored}</b><br/>
              </div>
            ) : (
              <Grid container spacing={0}>
                <Grid item md={6}>
                  <div>
                    <TextField required label="Column name for SKU" fullWidth
                               defaultValue={columnSKU} value={columnSKU}
                               onChange={event => {setColumnSKU(event.target.value)}}
                    />
                  </div>
                  <div>
                    <TextField required label="Column name for account name" fullWidth
                               defaultValue={columnAccount} value={columnAccount}
                               onChange={event => {setColumnAccount(event.target.value)}}
                    />
                  </div>
                  <div>
                    <TextField required label="Column name for product's platform id" fullWidth
                               defaultValue={columnPlatformId} value={columnPlatformId}
                               onChange={event => {setColumnPlatformId(event.target.value)}}
                    />
                  </div>
                </Grid>
                <Grid item md={1}></Grid>
                <Grid item md={5}>
                  <DropZone
                    apiPath='products/upload'
                    objectId={false}
                    objectType={"products.product"}
                    onUploadDone={onUploadDone}
                    accept={"text/csv, application/csv, application/vnd.ms-excel"}
                  />
                </Grid>
              </Grid>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={handleImport}
            color="primary"
            variant="contained"
            autoFocus
            disabled={!file || !columnSKU || !columnAccount || loading || result.imported !== null}
          >
            Start import
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
