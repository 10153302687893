import React, {useEffect, useState} from 'react'
import {Grid, MenuItem, OutlinedInput, Select} from '@material-ui/core'
import { TaskStateProvider } from '../../context/TaskStateProvider'
import {useTheme} from '@material-ui/styles'
// styles
import useStyles from './styles'
// components
import Widget from '../../components/Widget'
import {Typography} from '../../components/Wrappers'
import Table from './components/Table/Table'
import DashboardFilter from "./components/DashboardFilter"
import useMountEffect from "../../hooks/useMountEffect"
import ProductAPI from "../../apis/product"
import TopProduct from "./components/TopProduct";
import DesignerReport from "./components/DesignerReport";
import CreatorReport from "./components/CreatorReport";
import StatsReport from "./components/StatsReport";
import OrderReport from "./components/OrderReport";
import {makeStyles} from "@material-ui/core/styles";


function orderToTableData(orders) {
  const result = []
  for (let i in orders) {
    if (!orders[i]) {
      continue
    }
    const order = orders[i]
    result.push({
      'id': order.id,
      'product': order.product,
      'image': order.product?.first_image.thumbnail,
      'title': order.title,
      'creator_name': order.creator_name,
      'designer_name': order.designer_name,
      'platform': order.platform.name,
      'account': order.account,
      'quantity': order.quantity,
      'order_at': order.order_at
    })
  }
  return result
}

export default function Dashboard(props) {
  const classes = useStyles()
  const [conditions, setConditions] = useState({
    'fetch': false,
    'duration': 0,
    'platform': '',
    'duration_from': null,
    'duration_to': null,
    'designer': '',
    'creator': ''
  })

  const [topProductLimit, setTopProductLimit] = useState(10)

  const [orderData, setOrderData] = useState([{
    'id': '',
    'product_id': '',
    'creator_name': '',
    'designer_name': '',
    'title': '',
    'platform': '',
    'account': '',
    'quantity': '',
    'order_at': ''
  }])

  useEffect(() => {
    if (!conditions.fetch) {
      return
    }
    const condis = {
      'limit': '10',
      'platform': conditions.platform,
      'creator': conditions.creator,
      'designer': conditions.designer,
      'get_creator': true,
      'get_designer': true
    }
    ProductAPI.getOrders(condis).then(({results}) => {
      const data = orderToTableData(results)
      setOrderData(data)
    })
  }, [conditions])


  return (
    <>
      <TaskStateProvider>
      <DashboardFilter conditions={conditions} setConditions={setConditions}/>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Widget
            title='Stats'
            upperTitle
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <StatsReport conditions={conditions}/>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <OrderReport conditions={conditions}/>
        </Grid>

        <Grid item xs={12}>
          <Widget
            title='Top product'
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
            header={
              <div className={classes.mainChartHeader}>
                <Typography
                  variant='h5'
                  color='text'
                  colorBrightness='secondary'
                >
                  Top products
                </Typography>
                <Select
                  value={topProductLimit}
                  onChange={e => setTopProductLimit(e.target.value)}
                  input={
                    <OutlinedInput
                      labelWidth={0}
                      classes={{
                        notchedOutline: classes.mainChartSelectRoot,
                        input: classes.mainChartSelect
                      }}
                    />
                  }
                  autoWidth
                >
                  <MenuItem value={10}>10 products</MenuItem>
                  <MenuItem value={20}>20 products</MenuItem>
                  <MenuItem value={30}>30 products</MenuItem>
                  <MenuItem value={40}>40 products</MenuItem>
                  <MenuItem value={50}>50 products</MenuItem>
                  <MenuItem value={100}>100 products</MenuItem>
                </Select>
              </div>
            }
          >
            <TopProduct conditions={conditions} limit={topProductLimit}/>
          </Widget>
        </Grid>

        <Grid item xs={12}>
          <Widget
            title='Latest orders'
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            {orderData.length ? <Table data={orderData} classes={classes}/> : null}
          </Widget>
        </Grid>
        <Grid item xs={6}>
          <Widget
            title='Creator report'
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <CreatorReport conditions={conditions}/>
          </Widget>
        </Grid>

        <Grid item xs={6}>
          <Widget
            title='Designer report'
            upperTitle
            noBodyPadding
            disableWidgetMenu={true}
            bodyClass={classes.tableWidget}
          >
            <DesignerReport conditions={conditions}/>
          </Widget>
        </Grid>
      </Grid>
      </TaskStateProvider>
    </>
  )
}

