import React from 'react'
import { AuthAPI } from '../apis'
import Cookies from 'js-cookie'
const UserStateContext = React.createContext()
const UserDispatchContext = React.createContext()

function userReducer (state, action) {
  switch (action.type) {
    case 'LOGIN_SUCCESS':
      return { ...state, isAuthenticated: true }
    case 'SIGN_OUT_SUCCESS':
    case 'LOGIN_FAILURE':
      return { ...state, isAuthenticated: false }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

function UserProvider ({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!Cookies.get('token'),
    user: Cookies.get('user')
  })

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  )
}

function useUserState () {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUserState must be used within a UserProvider')
  }
  return context
}

function useUserDispatch () {
  const context = React.useContext(UserDispatchContext)
  if (context === undefined) {
    throw new Error('useUserDispatch must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut, updateTeam }

// ###########################################################

function loginUser (dispatch, login, password, history, setIsLoading, setError) {
  setError(false)
  setIsLoading(true)

  if (!!login && !!password) {
    AuthAPI.login(login, password).then((response) => {
      Cookies.set('token', response.token)
      Cookies.set('user', response.user)
      dispatch({ type: 'LOGIN_SUCCESS' })
      history.push('/app/dashboard')
    }).catch(() => {
      dispatch({ type: 'LOGIN_FAILURE' })
      setError(true)
      setIsLoading(false)
    })
  } else {
    dispatch({ type: 'LOGIN_FAILURE' })
    setError(true)
    setIsLoading(false)
  }
}

function signOut (dispatch, history) {
  Cookies.remove('token')
  Cookies.remove('user')
  dispatch({ type: 'SIGN_OUT_SUCCESS' })
  history.push('/login')
}

function updateTeam(teamId) {
  const u = JSON.parse(Cookies.get('user'))
  u.team =teamId
  Cookies.set('user', JSON.stringify(u))
}
