import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone'
import RootRef from '@material-ui/core/RootRef'
import Paper from '@material-ui/core/Paper'
import { AssetAPI } from '../../apis'
import LinearProgress from '@material-ui/core/LinearProgress'
import makeStyles from '@material-ui/core/styles/makeStyles'

const defaultText = 'Click or drop to upload'
const defaultExtraText = ''

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '10px',
    background: '#eee',
    padding: theme.spacing(1),
    outline: 'none',
    marginTop: theme.spacing(2),
    cursor: 'pointer'
  },
  small: {
    fontSize: '80%'
  }
}))

export default function DropZone (
  {
    objectId,
    objectType,
    objectProp,
    onUploadDone,
    apiPath,
    fieldName,
    accept,
    maxSize,
    textContent,
    extraTextContent,
    ...props
  }
) {
  const classes = useStyles();
  const text = textContent || defaultText;
  const extra = extraTextContent || defaultExtraText;
  const [uploading, setUploading] = React.useState(false)

  const onDrop = useCallback((acceptedFiles) => {
    setUploading(true)
    let aFile = null
    acceptedFiles.forEach((file) => {
      aFile = file
    })
    if (!aFile) {
      alert('File was not accepted')
      setUploading(false)
      return
    }
    AssetAPI.uploadFile(apiPath, objectId, objectType, objectProp, aFile, fieldName).then((data) => {
      onUploadDone(data)
      setUploading(false)
    }).catch(({data}) => {
      let error
      if (data.hasOwnProperty('non_field_errors')) {
        error = data.non_field_errors.join('.');
      } else {
        error = data.original;
      }
      alert(error)
      setUploading(false)
    })
  })

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept || 'image/jpeg, image/png, image/jpg',
    maxFiles: 1,
    maxSize: 104857600,
    onDrop: onDrop
  })
  const { ref, ...rootProps } = getRootProps()

  return (
    <RootRef rootRef={ref}>
      <Paper elevation={0} {...rootProps} className={classes.root} title={extra}>
        <div>
          <input {...getInputProps()} />
          {uploading ? (<p><LinearProgress /></p>) : (<p>{text}</p>)}
        </div>
      </Paper>
    </RootRef>
  )
}
