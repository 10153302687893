import API from './base'

export default {
  getTopProduct (conditions, limit) {
    conditions = Object.assign({}, conditions)
    conditions['limit'] = limit
    return API.get(`dashboard/top-product`, {params: conditions})
  },
  getDesignerReport(conditions) {
    return API.get(`dashboard/designer-report`, {params: conditions})
  },
  getCreatorReport(conditions) {
    return API.get(`dashboard/creator-report`, {params: conditions})
  },
  getStatsReport(conditions) {
    return API.get(`dashboard/stats-report`, {params: conditions})
  },
  getOrderReport(conditions) {
    return API.get(`dashboard/order-report`, {params: conditions})
  }
}
