import React from 'react'

// styles
import useStyles from './styles'

export default function InputCounter (props) {
  const classes = useStyles()
  const currentValue = props.currentValue || 0
  const maxLength = props.maxLength

  return (
    <div className={classes.counterContainer}>
      {currentValue} / {maxLength}
    </div>
  )
}
