import React from 'react'
import {Grid} from '@material-ui/core'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import IdeaModalEdit from './IdeaModalEdit'
import TaskSearch from './TaskSearch'
import {useTaskState} from '../../context/TaskStateProvider'
import {makeStyles} from '@material-ui/core/styles'
import {DesignAPI} from '../../apis'
import TablePagination from "@material-ui/core/TablePagination"
import {OpenInBrowser as OpenInBrowserIcon} from '@material-ui/icons'
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import IdeaModalAdd from "./IdeaModalAdd"
import Checkbox from "@material-ui/core/Checkbox"

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  cellThumbnail: {
    width: '100px',
    maxWidth: '100px',
    cursor: 'pointer',
  },
  cellThumbnailContainer: {
    width: '100px',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
  },
  action: {
    cursor: 'pointer'
  },
  editStatus: {
    transition: 'transform .2s',
    margin: '0 auto',
    color: 'white',
    fontSize: '11px',
    fontWeight: 'bold',
    padding: '6px 0px 3px 0px',
    transformOrigin: 'bottom left',
    '& span': {
      paddingTop: '0',
      marginRight: '2px',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'inline-block',
      borderWidth: '1px',
      borderStyle: 'solid',
      textAlign: 'center'
    },
  },
  editFinished: {
    background: '#04ad0d',
    borderColor: '#04AE0C',
  },
  editNotFinished: {
    background: '#c85d5e',
    borderColor: '#ae1919',
  },
})

const status = 'SPY'

const formatDate = (date) => {
  return date.split('.')[0].split('T')[0]
}

export default function IdeaList() {
  const classes = useStyles()
  const {setModalOpen, setIdeaId} = useTaskState()
  const [searchConditions, _setSearchConditions] = React.useState({})
  const [designs, setDesigns] = React.useState([])
  const [rowsPerPage, setRowsPerPage] = React.useState(50)
  const [page, setPage] = React.useState(0)
  const [count, setCount] = React.useState(0)
  const [fetching, setFetching] = React.useState(false)
  const [addModalOpen, setAddModalOpen] = React.useState(false)
  const [order, setOrder] = React.useState('desc')
  const [selected, setSelected] = React.useState([])

  const setSearchConditions = (conditions) => {
    setPage(0);
    _setSearchConditions(conditions)
  }

  const loadData = () => {
    let conditions = {}
    if (searchConditions) {
      conditions = searchConditions
    }
    conditions.context = 'list'
    conditions.limit = rowsPerPage
    conditions.offset = page * rowsPerPage
    conditions.get_creator = 1
    conditions.ignore_images = true
    conditions.get_design_status = 1
    if (!conditions.hasOwnProperty('designer') || !conditions.hasOwnProperty('creator') || !conditions.hasOwnProperty('search')) {
      return
    }
    if (fetching) {
      return
    }
    setFetching(true)
    DesignAPI.getIdeas(status, conditions).then(({count, results}) => {
      setDesigns(results)
      setCount(count)
    }).finally(() => {
      setFetching(false)
    })
  }
  React.useEffect(function () {
    loadData()
  }, [searchConditions, page, rowsPerPage])

  const handleSetId = (id) => {
    setModalOpen(true)
    setIdeaId(id)
  }

  const handleSetModalOpen = () => {
    setAddModalOpen(true)
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const handleAddSuccess = (response) => {
    setDesigns([response, ...designs])
  }

  const createSortHandler = (property) => (event) => {
    if (order == 'desc') {
      setOrder('asc')
      setSearchConditions({...searchConditions, 'ordering': 'id'})
    } else {
      setOrder('desc')
      setSearchConditions({...searchConditions, 'ordering': '-id'})
    }
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = []
      for(let item in designs) {
        newSelecteds.push(designs[item].id)
      }
      setSelected(newSelecteds)
    } else {
      setSelected([])
    }
  }

  const isSelected = (id) => selected.indexOf(id) !== -1

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      )
    }
    setSelected(newSelected)
  }

  const handleBuckDelete = (event) => {
    DesignAPI.deleteIdeas(selected).then(() => {
      loadData()
      setSelected([])
    })
  }

  return (
    <>
      <Grid container spacing={4}>
        <Grid item md={3}>
          <Button
            variant="contained"
            color="secondary"
            title="Delete selected idea(s)"
            onClick={handleBuckDelete}
            disabled={selected.length === 0}
          >
            Bulk delete
          </Button>
          &nbsp; &nbsp;
          <Button
            variant='contained'
            size='medium'
            color='primary'
            onClick={handleSetModalOpen}
          >
            Add idea
          </Button>
        </Grid>
        <Grid item md={8}>
          <TaskSearch setSearchConditions={setSearchConditions} filterDesigner={false} filterDate={false}/>
          <br/>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < designs.length}
                  checked={designs.length > 0 && selected.length === designs.length}
                  onChange={handleSelectAllClick}
                  inputProps={{'aria-label': 'Select all ideas'}}
                />
              </TableCell>
              <TableCell className={classes.cellThumbnail}></TableCell>
              <TableCell><b>Design title</b></TableCell>
              <TableCell><b>Creator</b></TableCell>
              <TableCell align='right' sortDirection={true}>
                <TableSortLabel
                  active={true}
                  direction={order}
                  onClick={createSortHandler('created_at')}
                >
                  <b>Date created</b>
                </TableSortLabel>
              </TableCell>
              <TableCell align='right'><b>Action</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {designs.map((design) => {
              const isItemSelected = isSelected(design.id)
              const labelId = `enhanced-table-checkbox-${design.id}`
              return (
              <TableRow
                  key={design.id}
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
              >
                <TableCell component='th' padding="checkbox" onClick={(event) => handleClick(event, design.id)}>
                  <Checkbox
                    checked={isItemSelected}
                    inputProps={{'aria-labelledby': labelId}}
                  />
                </TableCell>
                <TableCell component='th' scope='row' padding={'none'} className={classes.cellThumbnail}
                           onClick={() => {
                             handleSetId(design.id)
                           }}>
                  {!design.cover_image ? null : (
                    <div className={classes.cellThumbnailContainer}>
                      <img src={design.cover_image.thumbnail} className={classes.cellThumbnail} onClick={() => {
                        handleSetId(design.id)
                      }}/>
                    </div>
                  )}
                </TableCell>
                <TableCell>
                  <b style={{
                    'display': 'inline-block',
                    'margin-right': '5px',
                    'opacity': '0.6'
                  }}>#{design.id}</b> {design.title}
                  <div className={classes.editStatus}>
                    {design.design_status?.map((status) => {
                      return (<span key={Math.random()} title={status.product_type}
                                    className={status.edit_finished ? classes.editFinished : classes.editNotFinished}>{status.product_type[0]}</span>)
                    })}
                  </div>
                </TableCell>
                <TableCell>
                  {design.creator?.name}
                </TableCell>
                <TableCell align='right' title={design.created_at}>{formatDate(design.created_at)}</TableCell>
                <TableCell className={classes.action} align='right'>
                  <ButtonGroup variant="text" aria-label="text primary button group">
                    <Button onClick={() => {
                      handleSetId(design.id)
                    }} title={'View this idea'}><OpenInBrowserIcon/></Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            )})}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[50, 100, 150]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
      <IdeaModalEdit />
      <IdeaModalAdd modalOpen={addModalOpen} setModalOpen={setAddModalOpen} onAddSuccess={handleAddSuccess}/>
   </>
  )
}
