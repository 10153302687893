import React, {useEffect} from 'react'
import {ProductAPI} from '../../apis'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import useMountEffect from '../../hooks/useMountEffect'
import TextField from '@material-ui/core/TextField'
import {Grid} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

export default function SoldSearch(
  {
    setSearchConditions,
    ...props
  }
) {
  const toDate =  new Date()
  const fromDate = new Date()
  fromDate.setDate(toDate.getDate() - 7)

  const [searchTerm, setSearchTerm] = React.useState('');
  const [productTypes, setProductTypes] = React.useState([]);
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(fromDate);
  const [selectedDateTo, setSelectedDateTo] = React.useState(toDate);

  const [conditions, setConditions] = React.useState({
    status: '',
    search: '',
    product_type: '',
    order_at_from: '',
    order_at_to: '',
  })

  useMountEffect(() => {
    ProductAPI.getProductTypes().then(({results}) => {
      setProductTypes(results)
    })
  })

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setConditions({...conditions, search: searchTerm})
    }, 500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  useEffect(() => {
    const _conditions = Object.assign({}, conditions)
    setSearchConditions(_conditions)
  }, [conditions])

  const handleChangeProductType = (event) => {
    setConditions({...conditions, product_type: event.target.value})
  }

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date)
    setConditions({...conditions, order_at_from: date})
  }

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date)
    setConditions({...conditions, order_at_to: date})
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value)
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={6}>
          <TextField
            fullWidth
            placeholder='Search by SKU, title...'
            onChange={handleChangeSearchTerm}
          />
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.product_type}
              onChange={handleChangeProductType}
              displayEmpty
              inputProps={{'aria-label': 'All product type'}}
            >
              <MenuItem value=''>
                All product type
              </MenuItem>
              {productTypes.map((pt) => {
                return <MenuItem key={pt.id} value={pt.id}>{pt.full_name}</MenuItem>
              })}
              <MenuItem value='0'>
                No product type
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-from"
              format="MM/dd/yyyy"
              autoOk={true}
              value={selectedDateFrom}
              onChange={handleDateChangeFrom}
              KeyboardButtonProps={{
                'aria-label': 'Select sale date from',
              }}
              style={{"marginTop": '0'}}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-to"
              format="MM/dd/yyyy"
              autoOk={true}
              value={selectedDateTo}
              onChange={handleDateChangeTo}
              KeyboardButtonProps={{
                'aria-label': 'Select sale date to',
              }}
              style={{"marginTop": '0'}}
            />
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </div>
  )
}
