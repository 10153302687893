import React, {useEffect, useState} from 'react'
import {
  Table,
  TableRow,
  TableHead,
  TableBody,
  TableCell
} from '@material-ui/core'
import DashboardAPI from "../../../apis/dashboard"
import {makeStyles} from "@material-ui/core/styles";
import {useTaskState} from "../../../context/TaskStateProvider";
import Hashids from 'hashids'
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import {OpenInBrowser} from "@material-ui/icons"
import IdeaModalEdit from "../../../components/Designs/IdeaModalEdit";

const hid = new Hashids('PMM', 3)

const useStyles = makeStyles({
  cellThumbnail: {
    width: '100px',
    maxWidth: '100px',
    cursor: 'pointer',
  },
  cellThumbnailContainer: {
    width: '100px',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
  }
})


export default function TopProduct(
  {
    conditions,
    limit,
    ...props
  }
) {
  const {setModalOpen, setIdeaId, setDesignId} = useTaskState()
  const classes = useStyles()
  const keys = ['', 'SKU', 'IDEA', 'DESIGNER', 'TITLE', 'PLATFORM ID', 'ORDER', '']
  const [products, setProducts] = useState([])

  useEffect(() => {
    if (conditions.fetch) {
      DashboardAPI.getTopProduct(conditions, limit).then((response) => {
        setProducts(response.products)
      })
    }
  }, [conditions, limit])

  const handleSetId = (sku) => {
    const id = hid.decode(sku.split('-')[0].substring(1)).pop()
    const designId = hid.decode(sku.split('-')[1]).pop()
    setModalOpen(true)
    setIdeaId(id)
    setDesignId(designId)
  }

  const renderLink = (platform_id, platform_url) => {
    if(!platform_url) {
      return null
    }
    return (
      <a href={platform_url} target="_blank" rel="noreferrer noopener">{platform_id}</a>
    )
  }

  return (
    <>
    <Table className='mb-0'>
      <TableHead>
        <TableRow>
          {keys.map(key => (
            <TableCell key={key}>{key}</TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {products.map(({ sku, creator_name, designer_name, title, total, first_image, platform_id, platform_url }) => (
          <TableRow key={sku}>
            <TableCell component='th' scope='row' padding={'none'} className={classes.cellThumbnail}>
              {!first_image ? null : (
                <div className={classes.cellThumbnailContainer}>
                  <img src={first_image} className={classes.cellThumbnail} />
                </div>
              )}
            </TableCell>
            <TableCell>
              {sku}
            </TableCell>
            <TableCell>{creator_name}</TableCell>
            <TableCell>{designer_name}</TableCell>
            <TableCell className='pl-3 fw-normal'>{title}</TableCell>
            <TableCell>{renderLink(platform_id, platform_url)}</TableCell>
            <TableCell>{total}</TableCell>
            <TableCell align='right'>
                {!sku ? null : (
                    <ButtonGroup variant="text" aria-label="text primary button group">
                      <Button onClick={(e) => {
                        e.preventDefault()
                        handleSetId(sku)
                      }}><OpenInBrowser/></Button>
                    </ButtonGroup>
                    )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <IdeaModalEdit readOnly/>
    </>
  )
}
