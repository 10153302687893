import React from "react";
import IdealStepBase from "./IdeaStepBase";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const alertContents = {
  'success': 'This idea is marked as design done.',
  'error': 'This idea was not be able to mark as done.'
}


export default class IdeaStepDesign extends IdealStepBase {
  constructor(props) {
    super(props);
    this.idea = props.idea;
    this.state = {
      alertOpen: false,
      alertContent: alertContents.success,
      alertSeverity: 'success'
    };
    this.handleDesignDone = this.handleDesignDone.bind(this);
    this.handleAlertClose = this.handleAlertClose.bind(this);
    this.basicValidate = this.basicValidate.bind(this);
  }

  handleAlertClose = (event, reason) => {
    this.setState({...this.state, alertOpen: false});
  }


  assetValidate = () => {
    const errors = []
    return errors
  }

  basicValidate = () => {
    const errors = []
    for (let index in this.idea.designs) {
      const design = this.idea.designs[index]
      if (!design) {
        continue
      }
      if (Object.keys(design.product_type.design_validations).length > 0 && design.variants.length === 0) {
        errors.push(`Design or variant asset for design #${this.idea.designs[index].id} was not uploaded.`)
      }
      if (Object.keys(design.product_type.mockup_validations).length > 0 && design.images.length === 0) {
        errors.push(`Image or mockup asset for design #${this.idea.designs[index].id} was not uploaded.`)
      }
    }
    if (errors.length !== 0) {
      alert(errors.join("\n"))
      return false
    }
    return true
  }

  handleDesignDone = () => {
    const self = this
    if (!this.basicValidate()) {
      return false
    }

    const oldStatus = this.props.idea.status
    this.props.idea.status = 'DONE'
    this.props.saveIdea(true, function () {
      self.setState({
        ...self.state,
        alertOpen: true,
      })
      self.closeNotSave()
    }, function () {
      self.props.idea.status = oldStatus
    })
  }

  renderActions() {
    return (
      <>
        <Button variant="contained" color="primary" onClick={this.handleDesignDone}>
          Design done
        </Button>
        <Snackbar open={this.state.alertOpen} autoHideDuration={6000} onClose={this.handleAlertClose}>
          <Alert onClose={this.handleAlertClose} severity={this.state.alertSeverity}>
            {this.state.alertContent}
          </Alert>
        </Snackbar>
      </>
    )
  }
}
