import React from 'react'
import {AssetAPI, DesignAPI} from "../../apis"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles({
  imageContainer: {
    position: 'relative',
    display: 'block',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
    minHeight: '50px',
    paddingTop: '10px'
  },
  imageIndex: {
    position: 'absolute',
    padding: '0px 3px',
    lineHeight: '16px',
    top: '0',
    right: '0'
  },
  imageRemoveButton: {
    position: 'absolute',
    top: '0',
    left: '0px',
  },
  imageCoverButton: {
    position: 'absolute',
    top: '0',
    left: '26px',
  },
  imageThumb: {
    width: '100%',
    display: 'block',
    margin: 'auto'
  },
  formControl: {
    marginBottom: '15px'
  }
})

const formatDate = (date) => {
  return date.split('.')[0].replace('T', ' ')
}

export default function PreviewImage(
  {
    image,
    index,
    images,
    isReadOnly,
    refreshIdea,
    imageClass,
    imageStyle,
    setCoverFor,
    ...props
  }
) {
  const classes = useStyles()
  const [charCover, setCharCover] = React.useState('C')

  if (index) {

  }
  const handleRemoveImage = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    let _index = 0;
    for (_index = 0; _index < images.length; _index++) {
      if (images[_index].id === id) {
        break;
      }
    }
    images.splice(_index, 1);
    refreshIdea && refreshIdea();
    AssetAPI.deleteFile(id);
  }

  const handleSetCover = (event, id) => {
    event.preventDefault()
    event.stopPropagation()
    DesignAPI.setIdeaCover(setCoverFor, id).then(() => {
      setCharCover('✓')
    }).catch((error) => {
    })
  }

  return (
    <div>
      <a href={image.original} className={classes.imageContainer}
         target='_blank'
         rel="noopener noreferrer"
         title={`\nSize: ${image.width}x${image.height}\nDate: ${formatDate(image.created_at)}\nName: ${image.name}`}>
        <div className={imageClass}>
          <div style={imageStyle}>
            {(index === undefined) ? null : <button className={classes.imageIndex}>{index + 1}</button>}
            {isReadOnly ? null : (
              <button className={classes.imageRemoveButton} onClick={(e) => {
                handleRemoveImage(e, image.id);
              }} title={'Remove this image'}>X
              </button>
            )}
            {!setCoverFor || isReadOnly ? null : (
              <button className={classes.imageCoverButton} onClick={(e) => {
                handleSetCover(e, image.id);
              }} title={'Set as idea cover'}>{charCover}
              </button>
            )}
            <img src={image.thumbnail} className={classes.imageThumb}/>
          </div>
        </div>
      </a>
    </div>
  )
}
