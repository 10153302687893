import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'
import TextField from '@material-ui/core/TextField'

import { DesignAPI } from '../../apis'
import { Grid } from '@material-ui/core'
import DropZone from '../Upload/DropZone'
import Button from '@material-ui/core/Button'
import { useTaskState } from '../../context/TaskStateProvider'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import LinearProgress from "@material-ui/core/LinearProgress"

function Alert (props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

function getModalStyle () {
  const top = 50
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    bottom: '20px',
    transform: `translate(-${top}%, -${left}%)`
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: '80%',
    height: 'calc(100vh - 20px)',
    padding: theme.spacing(0),
    outline: 'none',
    display: 'block',
    overflow: 'hidden',
    borderRadius: '6px'
  },
  paperContent: {
    position: 'relative',
    width: '100%',
    height: 'calc(100vh - 120px)',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    marginTop: '50px',
    display: 'block',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  paperHeader: {
    position: 'fixed',
    top: '1px',
    left: '-1px',
    right: '0px',
    height: '50px',
    backgroundColor: '#EEE',
    paddingLeft: theme.spacing(4)
  },
  paperFooter: {
    position: 'fixed',
    bottom: '1px',
    left: '0px',
    height: '70px',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(6),
    textAlign: 'right'
  },
  image: {
    width: '100%'
  }
}))

const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?$', 'i') // fragment locator

const isValidURL = (str) => {
  return true
  return !!pattern.test(str)
}

const defaultMeta = {
  images: [],
  title: '',
  description: '',
  url: '',
  tags: []
}

export default function IdeaModalAdd (
  {
    modalOpen,
    setModalOpen,
    onAddSuccess,
    ...props
  }
) {
  let changed = false
  const classes = useStyles()
  const [modalStyle] = React.useState(getModalStyle)
  const [urlError, setUrlError] = React.useState(false)
  const [meta, setMeta] = React.useState(Object.assign({}, defaultMeta))
  const [inputURL, setInputURL] = React.useState('')
  const [added, setAdded] = React.useState([])
  const { setNeedRefresh } = useTaskState()
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [isParsing, setIsParsing] = React.useState(false)
  const [isAdding, setIsAdding] = React.useState(false)

  const handleClose = () => {
    setModalOpen(false)
    setMeta(Object.assign({}, defaultMeta))
    if (changed) {
      setNeedRefresh()
    }
  }

  const handleAddByURL = (event) => {
    const url = event.target.value
    setInputURL(url)

    if (!url) {
      setUrlError(false)
      return
    }

    if (!isValidURL(url)) {
      setUrlError(true)
    } else {
      setUrlError(false)
      setIsParsing(true)
      DesignAPI.getMetadata(url).then((response) => {
        response.images = [response.image]
        const _meta = Object.assign({'url': url}, meta, response)
        setMeta(_meta)
      }).finally(() => {
        setIsParsing(false)
      })
    }
  }

  const handleAddIdea = (close) => {
    if (meta.title === "") {
      alert("Please enter idea title.")
      return
    }
    setIsAdding(true)
    DesignAPI.addIdea(meta).then((response) => {
      changed = true
      setAdded([...added, response])
      setAlertOpen(true)
      setNeedRefresh()
      setMeta(Object.assign({}, defaultMeta))
      setInputURL('')
      onAddSuccess && onAddSuccess(response)
      if (close) {
        handleClose()
      }
    }).catch((error) => {
      alert(error)
    }).finally(() => {
      setIsAdding(false)
    })
  }

  const onUploadDone = (data) => {
    setMeta({ ...meta, image: data.image, images: [data.image] })
  }

  const handleOnFieldChange = (event) => {
    setMeta({ ...meta, [event.target.name]: event.target.value })
  }

  const handleAlertClose = () => {
    setAlertOpen(false)
  }

  return (
    <div>
      <Modal
        disableEnforceFocus
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby='add-modal-title'
        aria-describedby='add-modal-description'
      >
        <div style={modalStyle} className={classes.paper}>
          <div className={classes.paperHeader}>
            <h3>Add idea</h3>
          </div>
          <div className={classes.paperContent}>
            <TextField
              label='Add by URL'
              name='url'
              helperText='Enter valid url to parse data'
              fullWidth
              error={urlError}
              value={inputURL}
              onChange={handleAddByURL}
            />
            <LinearProgress color="secondary" hidden={!isParsing}/>

            <br /><br />
            <Grid container spacing={3} className={classes.panelItem}>
              <Grid item md={3}>
                {!meta.image ? null : (
                  <img src={meta.image} className={classes.image} />
                )}
                <DropZone objectId={1} objectType='designs.temporary' apiPath='images/temp_image' fieldName='file' onUploadDone={onUploadDone} />
              </Grid>
              <Grid item md={9}>
                <TextField
                  required
                  name='title'
                  label='Title'
                  fullWidth
                  onChange={handleOnFieldChange}
                  value={meta.title}
                />
                <br />
                <TextField
                  name='description'
                  label='Description'
                  fullWidth
                  multiline
                  rows={4}
                  onChange={handleOnFieldChange}
                  value={meta.description}
                />

                <br /><br />
                {added.map((item) => {
                  return (<div>Added: {item.title}</div>)
                })}
              </Grid>
            </Grid>
          </div>
          <div className={classes.paperFooter}>
            <Button variant='contained' color='primary' onClick={() => { handleAddIdea(false) }} disabled={isAdding || meta.title === ""}>
              Add
            </Button>
            &nbsp;
            <Button variant='contained' color='primary' onClick={() => { handleAddIdea(true) }} disabled={isAdding || meta.title === ""}>
              Add and close
            </Button>
          </div>
        </div>
      </Modal>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='success'>
          New idea was added successfully.
        </Alert>
      </Snackbar>
    </div>
  )
}
