import React from "react";
import IdealStepBase from "./IdeaStepBase";
import Button from "@material-ui/core/Button";
import {DesignAPI} from "../../apis";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { CheckCircle as CheckCircleIcon,  PostAdd as  PostAddIcon, Build as BuildCircleIcon } from "@material-ui/icons";

export default class IdeaStepReview extends IdealStepBase {
  constructor(props) {
    super(props);
    this.state = {
      alertOpen: false,
      total: 0,
      isReviewDone: props.idea.is_review_done
    }
    this.handleAlertClose = this.handleAlertClose.bind(this);
  }

  handleAlertClose = (event, reason) => {
    this.setState({...this.state, alertOpen: false})
  }

  handleExport = () => {
    DesignAPI.export(this.props.idea.id).then((response) => {
      this.setState({...this.state, alertOpen: true, total: response.total})
      this.props.setNeedRefresh()
      this.closeNotSave()
    }).catch((error) => {
      console.log(error)
    })
  }

  handleReviewDone = (event) => {
    this.setState({...this.state, isReviewDone: event.target.checked});
    this.props.idea.is_review_done = event.target.checked;
    this.props.saveIdea(false);
  }

  handleMoveToFix = (event) => {
    this.props.idea.status = 'FIX';
    this.props.saveIdea(true, () => {
      this.closeNotSave();
    });
  }

  handleMoveToDone = (event) => {
    this.props.idea.status = 'DONE';
    this.props.saveIdea(true, () => {
      this.closeNotSave();
    });
  }

  renderActions() {
    return (
      <>
        <FormControlLabel
          control={<Checkbox checked={this.state.isReviewDone} onChange={this.handleReviewDone} />}
          label={"Mark as review done"}
        />
        <Button variant="contained" color="primary" onClick={this.handleExport} disabled={!this.state.isReviewDone}>
          <PostAddIcon /> Export to product
        </Button>
        &nbsp;
        <Button variant="contained" color="info" onClick={this.handleMoveToDone} title={"Move to Done"}>
          <CheckCircleIcon />
        </Button>
        &nbsp;
        <Button variant="contained" color="info" onClick={this.handleMoveToFix} title={"Move to Fix"}>
          <BuildCircleIcon />
        </Button>
      </>
    )
  }
}
