import React, {useEffect} from 'react'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import {duration, Grid} from '@material-ui/core'
import {KeyboardDatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import useMountEffect from "../../../hooks/useMountEffect";
import {DesignAPI, ProductAPI} from "../../../apis";

export const Duration = {
  CUSTOM: 0,
  TODAY: 1,
  THIS_WEEK: 7,
  THIS_MONTH: 30,
  THIS_YEAR: 365
}

export default function DashboardFilter(
  {
    conditions,
    setConditions,
    ...props
  }
) {
  let toDate =  new Date()
  let fromDate = new Date()
  fromDate.setDate(toDate.getDate() - 7)

  const [selectedDuration, setSelectedDuration] = React.useState(Duration.CUSTOM)
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(fromDate)
  const [selectedDateTo, setSelectedDateTo] = React.useState(toDate)
  const [platforms, setPlatforms] = React.useState([])
  const [designers, setDesigners] = React.useState([])
  const [creators, setCreators] = React.useState([])

  useMountEffect(() => {
    ProductAPI.getPlatform().then(({ results }) => {
      setPlatforms(results)
      setConditions({...conditions, duration_from: fromDate, duration_to: toDate, fetch: true})
    })
    DesignAPI.getDesigners().then(({results}) => {
      setDesigners(results)
    })
    DesignAPI.getCreators().then(({results}) => {
      setCreators(results)
    })
  })

  const handleChangePlatform = (event) => {
    setConditions({ ...conditions, platform: event.target.value })
  }

  const handleChangeDuration = (event) => {
    const value = event.target.value
    if (value !== Duration.CUSTOM) {
      toDate =  new Date()
      fromDate = new Date()
      setSelectedDateTo(toDate)
      fromDate.setDate(toDate.getDate() - value)
      setSelectedDateFrom(fromDate)
      setConditions({...conditions, duration_from: fromDate, duration_to: toDate, duration: value})
    }
    setSelectedDuration(value)
  }

  const handleDateChangeFrom = (date) => {
    setSelectedDateFrom(date)
    setConditions({...conditions, duration_from: date})
    setSelectedDuration(Duration.CUSTOM)
  }

  const handleDateChangeTo = (date) => {
    setSelectedDateTo(date)
    setConditions({...conditions, duration_to: date})
    setSelectedDuration(Duration.CUSTOM)
  }

  const handleChangeDesigner = (event) => {
    setConditions({...conditions, designer: event.target.value})
  }

  const handleChangeCreator = (event) => {
    setConditions({...conditions, creator: event.target.value})
  }

  return (
    <div>
      <Grid container spacing={1}>
        <Grid item md={1}>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.platform}
              onChange={handleChangePlatform}
              displayEmpty
              inputProps={{'aria-label': 'All platform'}}
            >
              <MenuItem key={'_'} value=''>All platform</MenuItem>
              {platforms.map((ps) => {
                return <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.creator}
              onChange={handleChangeCreator}
              displayEmpty
              inputProps={{'aria-label': 'All creators'}}
            >
              <MenuItem value=''>
                All creators
              </MenuItem>
              {creators.map((creator) => {
                return <MenuItem key={creator.id} value={creator.id}>{creator.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <FormControl fullWidth>
            <Select
              value={conditions.designer}
              onChange={handleChangeDesigner}
              displayEmpty
              inputProps={{'aria-label': 'All designers'}}
            >
              <MenuItem value=''>
                All designers
              </MenuItem>
              {designers.map((designer) => {
                return <MenuItem key={designer.id} value={designer.id}>{designer.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-from"
              format="MM/dd/yyyy"
              autoOk={true}
              value={selectedDateFrom}
              onChange={handleDateChangeFrom}
              KeyboardButtonProps={{
                'aria-label': 'Select sale date from',
              }}
              style={{"marginTop": '0'}}
              title="Select created date from"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={2}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              margin="normal"
              id="date-picker-to"
              format="MM/dd/yyyy"
              autoOk={true}
              value={selectedDateTo}
              onChange={handleDateChangeTo}
              KeyboardButtonProps={{
                'aria-label': 'Select created date to',
              }}
              style={{"marginTop": '0'}}
              title="Select created date to"
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item md={1}>
          <FormControl fullWidth>
            <Select
              value={selectedDuration}
              onChange={handleChangeDuration}
              displayEmpty
              inputProps={{'aria-label': 'All status'}}
            >
                <MenuItem value={Duration.CUSTOM}>Custom</MenuItem>
                <MenuItem value={Duration.TODAY}>YesToday</MenuItem>
                <MenuItem value={Duration.THIS_WEEK}>This week</MenuItem>
                <MenuItem value={Duration.THIS_MONTH}>This month</MenuItem>
                <MenuItem value={Duration.THIS_YEAR}>This year</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  )
}
