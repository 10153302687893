import { makeStyles } from '@material-ui/styles'

export default makeStyles(theme => ({
  dashedBorder: {
    border: '1px dashed',
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginTop: theme.spacing(1)
  },
  text: {
    marginBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  panelItem: {
    marginBottom: theme.spacing(1)
  },
  panelItemImage: {
    width: '95%'
  },
  transparentBackground: {
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)'
  },
  idealImage: {
    position: 'relative',
    width: '100%',
    minHeight: '100px',
    backgroundImage: 'url(https://opengameart.org/sites/default/files/Transparency100.png)',
    marginBottom: '10px',

    '&:after': {
      content: '""',
      display: 'block',
      paddingBottom: '100%'
    }
  },
  ideaImageContent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundPosition: 'center center'
  },
  fieldSet: {
    borderRadius: '4px',
    padding: theme.spacing(2),
    border: '#ccc 1px solid',
    marginTop: theme.spacing(4)
  }
}))
