import React from 'react'
import {Grid} from '@material-ui/core'
import useStyles from './styles'
import Button from '@material-ui/core/Button'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import DesignAPI from '../../apis/design'
import DropZone from '../Upload/DropZone'
import {AssetAPI, ProductAPI} from '../../apis'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import useMountEffect from '../../hooks/useMountEffect'
import {TagAutosuggest} from './AutoSuggest'
import PreviewImage from "./PreviewImage";

function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

export default function IdeaDetail(
  {
    idea,
    setIdea,
    saveIdea,
    onAddDesignDone,
    readOnly,
    ...props
  }
) {
  const classes = useStyles()

  const [platform, setPlatform] = React.useState('')
  const [productType, setProductType] = React.useState('')
  const [platforms, setPlatforms] = React.useState([])
  const [productTypes, setProductTypes] = React.useState([])
  const [alertOpen, setAlertOpen] = React.useState(false)
  const [, setRefresh] = React.useState()

  useMountEffect(() => {
    ProductAPI.getPlatform().then(({results}) => {
      setPlatforms(results)
      setPlatform(results[0].id)
    })
  })

  React.useEffect(() => {
    if(platform) {
      ProductAPI.getProductTypes(platform).then(({results}) => {
        setProductTypes(results)
        setProductType(results[0].id)
      })
    }
  }, [platform])

  const handleAlertClose = (event, reason) => {
    setAlertOpen(false)
  }

  const handleChangePlatform = (event) => {
    setPlatform(event.target.value)
  }

  const changeFormValue = (target) => {
    const targetName = target.name
    if (targetName && idea.hasOwnProperty(targetName)) {
      idea[targetName] = target.value
    }
    if (targetName.indexOf('meta.') === 0) {
      const metaKey = targetName.replace('meta.', '')
      idea[metaKey] = target.value
    }
  }

  const handleChangeFormElement = (event) => {
    changeFormValue(event.target)
  }

  const handlePlatFormChange = (event) => {
    setProductType(event.target.value)
  }

  const refreshView = () => {
    refreshIdea()
    setRefresh(true)
  }

  const refreshIdea = () => {
    const _idea = Object.assign({}, idea)
    setIdea(_idea)
  }

  const handleAddDesign = (event) => {
    DesignAPI.addDesign(idea.id, productType, idea.tags).then((data) => {
      idea.designs.push(data)
      refreshView()
      setAlertOpen(true)
      onAddDesignDone && onAddDesignDone(data)
    })
  }

  const handleUpdateTags = (tags) => {
    idea.tags = tags
    saveIdea()
  }

  const handleOnBlur = () => {
    saveIdea()
  }

  const onUploadImageDone = (data) => {
    idea.images.push(data)
    refreshView()
  }

  const onUploadAssetDone = (data) => {
    idea.files.push(data)
    refreshView()
  }

  const renderFile = (file) => {
    return (
      <a href={file.original} target='_blank' rel="noopener noreferrer">{file.name}</a>
    )
  }

  return (
    <Grid container spacing={2} className={classes.panelItem}>
      <Grid item xs={2} md={2}>
        {idea.images
          ? idea.images.map(image => {
            return <PreviewImage key={image.id} image={image} images={idea.images} refreshIdea={refreshIdea} isReadOnly={readOnly} />
          })
          : null}
        {!!readOnly ? null : (
          <DropZone
            apiPath={'images'}
            objectId={idea.id} objectType='designs.idea'
            onUploadDone={onUploadImageDone}
            textContent="Upload images"
          />
        )}
      </Grid>
      <Grid item xs={10} md={10}>
        <TextField
          required
          id='idea-title'
          name='title'
          onChange={handleChangeFormElement}
          label='Title'
          fullWidth
          defaultValue={idea.title}
          InputProps={{
            readOnly: !!readOnly,
          }}
          onBlur={handleOnBlur}
        />
        <br/>
        <TextField
          required
          id='idea-desc'
          name='description'
          onChange={handleChangeFormElement}
          label='Description'
          fullWidth
          multiline
          rows={4}
          defaultValue={idea.description}
          InputProps={{
            readOnly: !!readOnly,
          }}
          onBlur={handleOnBlur}
        />
        <br/>

        <TagAutosuggest tags={idea.tags} updateTags={handleUpdateTags}/>
        {!!readOnly ? null : (
        <div>
          <Grid container spacing={0}>
            <Grid item md={6}></Grid>
            <Grid item md={6}>
              <Grid container spacing={2}>
                <Grid item md={5}>
                  <FormControl fullWidth>
                    <Select
                      value={platform}
                      onChange={handleChangePlatform}
                      displayEmpty
                      inputProps={{'aria-label': 'All platform'}}
                    >
                    <MenuItem value='' disabled>
                      Select platform
                    </MenuItem>
                      {platforms.map((ps) => {
                        return <MenuItem key={ps.id} value={ps.id}>{ps.name}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={5}>
                  <FormControl fullWidth>
                    <Select
                      label='Platform'
                      value={productType}
                      displayEmpty
                      onChange={handlePlatFormChange}
                    >
                    <MenuItem value='' disabled>
                      Select product type
                    </MenuItem>
                    {productTypes.map((pt) => {
                      return <MenuItem value={pt.id}>{pt.name}</MenuItem>
                    })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={2}>
                  <FormControl fullWidth={true}>
                    <Button variant='outlined' color='primary' onClick={handleAddDesign} fullWidth={true}>
                      Add
                    </Button>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        )}

          <Grid container>
            <Grid item md={10}>
            {idea.files
              ? idea.files.map(file => {
                return <div key={file.id}>{renderFile(file)}</div>
              })
              : null}
            </Grid>
            <Grid item md={2}>
              {!!readOnly ? null : (
              <DropZone
                objectId={idea.id} objectType='designs.idea'
                onUploadDone={onUploadAssetDone}
                apiPath={'files'}
                textContent="Upload asset files"
                accept='font/ttf, image/vnd.adobe.photoshop, application/psd, image/psd, application/zip, application/rar'
              />
              )}
            </Grid>
          </Grid>
      </Grid>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity='success'>
          New design was added.
        </Alert>
      </Snackbar>
    </Grid>
  )
}
