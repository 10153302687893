import React, { useState } from 'react'
import { AppBar, IconButton, Menu, Toolbar } from '@material-ui/core'
import { ArrowBack as ArrowBackIcon, Menu as MenuIcon, Person as AccountIcon } from '@material-ui/icons'
import classNames from 'classnames'
// styles
import useStyles from './styles'
// components
import { Typography } from '../Wrappers'
// context
import { toggleSidebar, useLayoutDispatch, useLayoutState } from '../../context/LayoutContext'
import { signOut, updateTeam, useUserDispatch, useUserState } from '../../context/UserContext'
import useMountEffect from "../../hooks/useMountEffect";
import {AuthAPI, DesignAPI, ProductAPI} from "../../apis";
import {numberWithCommas} from "../../pages/dashboard/utils";

export default function Header (props) {
  const classes = useStyles()

  // global
  const layoutState = useLayoutState()
  const layoutDispatch = useLayoutDispatch()
  const userDispatch = useUserDispatch()
  const { user } = useUserState()
  const [teams, setTeams ] = useState([])
  const [currentTeam, setCurrentTeam] = useState(null)
  const User = JSON.parse(user)

  const [profileMenu, setProfileMenu] = useState(null)
  const [isSearchOpen, setSearchOpen] = useState(false)

  const setTeam = (teamId) => {
      AuthAPI.setTeam(teamId).then((response) => {
        updateTeam(teamId)
        window.location.reload()
      }).catch(() => {
        alert("Unable to switch the team.")
      })
  }

  useMountEffect(() => {
    if(user) {
      AuthAPI.getTeams().then(({results}) => {
        setTeams(results)
        for(let item in results) {
          if(User.team == results[item].id) {
            setCurrentTeam(results[item])
            break
          }
        }
      }).catch(() => {
      })
    }
  })

  return (
    <AppBar position='fixed' className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          color='inherit'
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButtonSandwich,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                )
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                )
              }}
            />
          )}
        </IconButton>
        <Typography variant='h6' weight='medium' className={classes.logotype}>
          <b>{currentTeam?.name}</b>
        </Typography>
        <div className={classes.grow} />
        <div
          className={classNames(classes.search, {
            [classes.searchFocused]: isSearchOpen
          })}
        />
        <IconButton
          aria-haspopup='true'
          color='inherit'
          className={classes.headerMenuButton}
          aria-controls='profile-menu'
          onClick={e => setProfileMenu(e.currentTarget)}
        >
          <AccountIcon classes={{ root: classes.headerIcon }} />
        </IconButton>

        <Menu
          id='profile-menu'
          open={Boolean(profileMenu)}
          anchorEl={profileMenu}
          onClose={() => setProfileMenu(null)}
          className={classes.headerMenu}
          classes={{ paper: classes.profileMenu }}
          disableAutoFocusItem
        >
          <div className={classes.profileMenuUser}>
            <Typography variant='h4' weight='medium'>
              {User.email}
            </Typography>
          </div>
          {teams.map(({ id, name }) => (
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              onClick={() => setTeam(id)}
            >
              { name }
            </Typography>
          </div>
          ))}
          <div className={classes.profileMenuUser}>
            <Typography
              className={classes.profileMenuLink}
              color='primary'
              onClick={() => signOut(userDispatch, props.history)}
            >
              Sign Out
            </Typography>
          </div>
        </Menu>
      </Toolbar>
    </AppBar>
  )
}
